import React from 'react';
import HomeCarousel from './components/HomeCarousel.js'
import ImageBox from './components/ImageBox.js'
import Social from './components/Social.js'
import './styles/home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSitemap, faCode } from '@fortawesome/free-solid-svg-icons'
import { Link } from "react-router-dom";


class Home extends React.Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }


  render(){
    const { welcome, cards, slides, bottomBox } = this.props.data

    return(
      <div>

        <div className="welcome">
          <div className="welcome-text">
            <p className="welcome-title">{welcome.title}</p>
            {welcome.texts.map((t,i) =>
              <p key={`${i}`}>{t}</p>
            )}
            <p className="welcome-subtitle">{welcome.subtitle}</p>
          </div>
          <img src={welcome.image} alt="welcome-pic"/>
        </div>

        <div className="cards-container">
          {cards.map((c,i) =>
            <Link to={c.link} key={`key_${i}`}>
              <div className="card">
                <div className="card-icon">
                  <img src={c.icon} alt="chart"/>
                </div>
                <div className="card-text">
                  <p className="card-title">{c.title}</p>
                  <p>{c.text}</p>
                </div>
              </div>
            </Link>
          )}
        </div>

        <div className="home-carousel-container">
          <HomeCarousel data={slides}/>
        </div>

        <div className="partner-container">
          <ImageBox
            data={bottomBox}
            imageSide="right"
            />
        </div>

        <Social/>
        <div className="api-terms">
          <p>
            You are accessing and using the APIs under these
            <a
              href="https://finconecta.com/terms-of-use-apis/"
              target="_blank"
              rel="noopener noreferrer"
              > Terms and Conditions </a>.
          </p>
        </div>
      </div>
    )
  }
}

export default Home;
