import React from 'react';
import PhonelinkRingOutlinedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import HouseIcon from '@material-ui/icons/House';
import SendIcon from '@material-ui/icons/Send';
import Icon from '@material-ui/core/Icon'


export default function getUseCaseIcon(useCase){
  switch(useCase){
    case 'transfer':
    return <PhonelinkRingOutlinedIcon/>
    case 'atm':
    return <CreditCardOutlinedIcon/>
    case 'services':
    return <HouseIcon/>
    case 'qr':
    return <Icon>qr_code</Icon>
    case 'cash':
    return <Icon>payments</Icon>
    case 'message':
    return <SendIcon/>
    case 'chatbot':
    return <Icon>support_agent</Icon>
    default:
    return ''
  }
}
