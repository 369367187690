import React, { useState } from 'react';
import { Link } from "react-router-dom";
import getUseCaseIcon from '../Utils.js'

const MegaMenu = (props)=>{
  const { data, selected, openSubMenu } = props

    return(
      <div style={{width: '100%', height: '100%'}}>
        {selected === 'useCases' &&
          <div className="use-cases">
            <div className="message">
              <h1>{data.useCases.messageTitle}</h1>
              <p>{data.useCases.message}</p>
            </div>
            <div className="column-one">
              {data.useCases.useCaseList.columnOne.map((u, i) =>
                <Link className="use-case" key={i} to={u.path} onClick={(e) => openSubMenu('')}>
                  {getUseCaseIcon(u.path.split('=')[1])}
                  <p>{u.text}</p>
                </Link>
              )}
            </div>
            <div className="column-two">
              {data.useCases.useCaseList.columnTwo.map((u, i) =>
                <Link className="use-case" key={i} to={u.path} onClick={(e) => openSubMenu('')}>
                  {getUseCaseIcon(u.path.split('=')[1])}
                  <p>{u.text}</p>
                </Link>
              )}
            </div>
          </div>
        }
      </div>
    )
  }

export default MegaMenu
