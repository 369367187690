import React, { useState } from 'react';
import Language from '@material-ui/icons/Language';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from "react-router-dom";
import MegaMenu from './MegaMenu.js'

class Navbar extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      selectedSubMenu: ''
    }
    this.openSubMenu = this.openSubMenu.bind(this);
  }

  openSubMenu(subMenu){
    this.setState({selectedSubMenu: subMenu})
  }

  render(){
    const { data, language } = this.props
    const { selectedSubMenu } = this.state

    return(
      <div className="main-navbar-container">
        <div className="language-section" onMouseEnter={() => this.openSubMenu('')}>
          <div className="language-container">
            <Language className="language" />
            <Select className="switch-language" disableUnderline id="lang-switch" value={language} onChange={(e) => this.props.handleLanguageSwitch(e.target.value)}>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="es">Español</MenuItem>
            </Select>
          </div>
        </div>
        <div className="navbar-container">
          <div className="navbar-logo-container">
            <Link to="/"><img height="40px" alt="bank-logo" src={data.logo}/></Link>
            <p>{data.mainTitle}</p>
          </div>
          <div className="nav-links">
            {data.links.map((l,i) =>
              l.path && (l.path.includes('http') || l.path.includes('www'))?
              (l.separator?
                <p key={`key_${i}`} className="separator"/> :
                  <a
                    key={`key_${i}`}
                    href={l.path}
                    className={l.megaMenu? "mega-menu": ''}
                    onMouseEnter={() => this.openSubMenu(l.megaMenu)}
                    onClick={(e) => this.openSubMenu('')}
                    >
                    <p>{l.label}</p>
                  </a>) :
                  (l.separator?
                    <p key={`key_${i}`} className="separator"/> :
                      <Link
                        key={`key_${i}`}
                        to={l.path}
                        className={l.megaMenu? "mega-menu": ''}
                        onMouseEnter={() => this.openSubMenu(l.megaMenu)}
                        onClick={(e) => this.openSubMenu('')}
                        >
                        <p>{l.label}{l.megaMenu && (selectedSubMenu?
                             <ExpandLessIcon
                               style={{color: '#ec008c', fontSize: '1.5em'}}
                               /> :
                               <ExpandMoreIcon
                                 style={{color: '#ec008c', fontSize: '1.5em'}}
                                 />
                            )}</p>
                      </Link>)
                    )}
                    {selectedSubMenu?
                      <div className="sub-menu" onMouseLeave={() => this.openSubMenu('')}>
                        <MegaMenu
                          selected={selectedSubMenu}
                          language={language}
                          data={data.megaMenus}
                          openSubMenu={this.openSubMenu}
                          />
                      </div>:
                      ''
                    }
                  </div>
                </div>
              </div>
            )
          }
  }

export default Navbar
