import React from "react";

export default function ApiIcon (props) {
    const portalColor = "rgba(236, 0, 140, 1)";

    const getIconByApiReference = (reference, size) => {
        switch(reference){
            // Auth - CREATE
            case "TOKEN":
            case "POST_CONSENT_FDX":
            case "POST_ACCESS_CONSENT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h100v100H0z"/>
                            <path d="M5.357 3.571h89.286v89.286H5.357z"/>
                            <path d="M50 51.935c-4.092 0-7.44 3.348-7.44 7.44 0 4.092 3.348 7.44 7.44 7.44 4.092 0 7.44-3.348 7.44-7.44 0-4.092-3.348-7.44-7.44-7.44zm22.321-18.602h-3.72v-7.44c0-10.268-8.333-18.601-18.601-18.601a18.586 18.586 0 0 0-18.006 13.95 3.705 3.705 0 0 0 2.679 4.54c1.971.52 4.017-.67 4.538-2.68A11.146 11.146 0 0 1 50 14.733c6.138 0 11.16 5.022 11.16 11.16v7.441H27.68c-4.093 0-7.44 3.349-7.44 7.44v37.203c0 4.092 3.347 7.44 7.44 7.44H72.32c4.093 0 7.44-3.348 7.44-7.44V40.774c0-4.092-3.347-7.44-7.44-7.44zm0 40.923a3.731 3.731 0 0 1-3.72 3.72H31.4a3.731 3.731 0 0 1-3.72-3.72V44.494a3.731 3.731 0 0 1 3.72-3.72H68.6a3.731 3.731 0 0 1 3.72 3.72v29.762z" fill="#7D8496" fill-rule="nonzero"/>
                            <g fill-rule="nonzero">
                                <path d="M98.74 77.777a16.09 16.09 0 0 0-5.8-7.04A15.916 15.916 0 0 0 84 68c-2.203 0-4.31.449-6.223 1.26a16.092 16.092 0 0 0-7.04 5.799A15.916 15.916 0 0 0 68 84c0 2.203.449 4.31 1.26 6.223a16.09 16.09 0 0 0 5.8 7.04A15.916 15.916 0 0 0 84 100c2.203 0 4.31-.449 6.223-1.26a16.091 16.091 0 0 0 7.04-5.799A15.916 15.916 0 0 0 100 84c0-2.203-.449-4.31-1.26-6.223z" fill={portalColor}/>
                                <path d="M92.743 83.35h-7.306v-7.306a1.044 1.044 0 0 0-2.087 0v7.306h-7.306a1.044 1.044 0 0 0 0 2.087h7.306v7.306a1.044 1.044 0 0 0 2.087 0v-7.306h7.306a1.044 1.044 0 0 0 0-2.087z" fill="#FFF"/>
                            </g>
                        </g>
                    </svg>
                );
            // Auth - GET
            case "GET_CONSENT_FDX":
            case "GET_ACCESS_CONSENT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h100v100H0z"/>
                            <path d="M5.357 3.571h89.286v89.286H5.357z"/>
                            <path d="M50 51.935c-4.092 0-7.44 3.348-7.44 7.44 0 4.092 3.348 7.44 7.44 7.44 4.092 0 7.44-3.348 7.44-7.44 0-4.092-3.348-7.44-7.44-7.44zm22.321-18.602h-3.72v-7.44c0-10.268-8.333-18.601-18.601-18.601a18.586 18.586 0 0 0-18.006 13.95 3.705 3.705 0 0 0 2.679 4.54c1.971.52 4.017-.67 4.538-2.68A11.146 11.146 0 0 1 50 14.733c6.138 0 11.16 5.022 11.16 11.16v7.441H27.68c-4.093 0-7.44 3.349-7.44 7.44v37.203c0 4.092 3.347 7.44 7.44 7.44H72.32c4.093 0 7.44-3.348 7.44-7.44V40.774c0-4.092-3.347-7.44-7.44-7.44zm0 40.923a3.731 3.731 0 0 1-3.72 3.72H31.4a3.731 3.731 0 0 1-3.72-3.72V44.494a3.731 3.731 0 0 1 3.72-3.72H68.6a3.731 3.731 0 0 1 3.72 3.72v29.762z" fill="#7D8496" fill-rule="nonzero"/>
                            <g fill-rule="nonzero">
                                <path d="M98.74 77.777a16.09 16.09 0 0 0-5.8-7.04A15.916 15.916 0 0 0 84 68c-2.203 0-4.31.449-6.223 1.26a16.092 16.092 0 0 0-7.04 5.799A15.916 15.916 0 0 0 68 84c0 2.203.449 4.31 1.26 6.223a16.09 16.09 0 0 0 5.8 7.04A15.916 15.916 0 0 0 84 100c2.203 0 4.31-.449 6.223-1.26a16.091 16.091 0 0 0 7.04-5.799A15.916 15.916 0 0 0 100 84c0-2.204-.449-4.31-1.26-6.223z" fill={portalColor}/>
                                <path d="m87.659 89.644-7.516-5.983 7.516-5.983c.35-.28.447-.847.214-1.269-.233-.42-.706-.536-1.057-.256l-8.475 6.745c-.212.17-.341.457-.341.763s.129.593.341.763l8.475 6.745c.35.28.824.165 1.057-.256.233-.422.137-.99-.214-1.269z" fill="#FFF"/>
                            </g>
                        </g>
                    </svg>
                )
            // Auth - DELETE
            case "DELETE_CONSENT_FDX":
            case "DELETE_ACCESS_CONSENT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd">
                            <path d="M0 0h100v100H0z"/>
                            <path d="M5.357 3.571h89.286v89.286H5.357z"/>
                            <path d="M50 51.935c-4.092 0-7.44 3.348-7.44 7.44 0 4.092 3.348 7.44 7.44 7.44 4.092 0 7.44-3.348 7.44-7.44 0-4.092-3.348-7.44-7.44-7.44zm22.321-18.602h-3.72v-7.44c0-10.268-8.333-18.601-18.601-18.601a18.586 18.586 0 0 0-18.006 13.95 3.705 3.705 0 0 0 2.679 4.54c1.971.52 4.017-.67 4.538-2.68A11.146 11.146 0 0 1 50 14.733c6.138 0 11.16 5.022 11.16 11.16v7.441H27.68c-4.093 0-7.44 3.349-7.44 7.44v37.203c0 4.092 3.347 7.44 7.44 7.44H72.32c4.093 0 7.44-3.348 7.44-7.44V40.774c0-4.092-3.347-7.44-7.44-7.44zm0 40.923a3.731 3.731 0 0 1-3.72 3.72H31.4a3.731 3.731 0 0 1-3.72-3.72V44.494a3.731 3.731 0 0 1 3.72-3.72H68.6a3.731 3.731 0 0 1 3.72 3.72v29.762z" fill="#7D8496" fill-rule="nonzero"/>
                            <g fill-rule="nonzero">
                                <path d="M98.74 77.777a16.09 16.09 0 0 0-5.8-7.04A15.916 15.916 0 0 0 84 68c-2.203 0-4.31.449-6.223 1.26a16.092 16.092 0 0 0-7.04 5.799A15.916 15.916 0 0 0 68 84c0 2.203.449 4.31 1.26 6.223a16.09 16.09 0 0 0 5.8 7.04A15.916 15.916 0 0 0 84 100c2.203 0 4.31-.449 6.223-1.26a16.091 16.091 0 0 0 7.04-5.799A15.916 15.916 0 0 0 100 84c0-2.203-.449-4.31-1.26-6.223z" fill={portalColor}/>
                                <path d="M92.356 82.942H75.644c-.577 0-1.045.474-1.045 1.058 0 .584.468 1.058 1.045 1.058h16.712c.577 0 1.045-.474 1.045-1.058 0-.584-.468-1.058-1.045-1.058z" fill="white"/>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_VALIDATE_TOKEN":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-token-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-810.000000, -647.000000)">
                                <g id="ic-token-chevron" transform="translate(810.000000, 647.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <g id="vpn_key_black_24dp" transform="translate(14.000000, 14.000000)">
                                        <polygon id="Path" points="0 0 72 0 72 72 0 72"></polygon>
                                        <path d="M38.1245485,29.4623869 C35.0152076,20.6580425 25.8508342,14.7339298 15.606374,16.7631839 C8.11122582,18.2687595 2.02346352,24.2583321 0.452428091,31.7534803 C-2.2314241,44.4199534 7.39116791,55.6463108 19.6321523,55.6463108 C28.1746575,55.6463108 35.4406963,50.1804167 38.1245485,42.5543489 L52.3620571,42.5543489 L52.3620571,49.1003298 C52.3620571,52.7006193 55.3077485,55.6463108 58.9080381,55.6463108 C62.5083276,55.6463108 65.454019,52.7006193 65.454019,49.1003298 L65.454019,42.5543489 C69.0543086,42.5543489 72,39.6086574 72,36.0083679 C72,32.4080784 69.0543086,29.4623869 65.454019,29.4623869 L38.1245485,29.4623869 Z M19.6321523,42.5543489 C16.0318628,42.5543489 13.0861713,39.6086574 13.0861713,36.0083679 C13.0861713,32.4080784 16.0318628,29.4623869 19.6321523,29.4623869 C23.2324418,29.4623869 26.1781333,32.4080784 26.1781333,36.0083679 C26.1781333,39.6086574 23.2324418,42.5543489 19.6321523,42.5543489 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(66.000000, 66.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_TOKEN":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-token-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-563.000000, -647.000000)">
                                <g id="ic-token-plus" transform="translate(563.000000, 647.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <g id="vpn_key_black_24dp" transform="translate(14.000000, 14.000000)">
                                        <polygon id="Path" points="0 0 72 0 72 72 0 72"></polygon>
                                        <path d="M38.1245485,29.4623869 C35.0152076,20.6580425 25.8508342,14.7339298 15.606374,16.7631839 C8.11122582,18.2687595 2.02346352,24.2583321 0.452428091,31.7534803 C-2.2314241,44.4199534 7.39116791,55.6463108 19.6321523,55.6463108 C28.1746575,55.6463108 35.4406963,50.1804167 38.1245485,42.5543489 L52.3620571,42.5543489 L52.3620571,49.1003298 C52.3620571,52.7006193 55.3077485,55.6463108 58.9080381,55.6463108 C62.5083276,55.6463108 65.454019,52.7006193 65.454019,49.1003298 L65.454019,42.5543489 C69.0543086,42.5543489 72,39.6086574 72,36.0083679 C72,32.4080784 69.0543086,29.4623869 65.454019,29.4623869 L38.1245485,29.4623869 Z M19.6321523,42.5543489 C16.0318628,42.5543489 13.0861713,39.6086574 13.0861713,36.0083679 C13.0861713,32.4080784 16.0318628,29.4623869 19.6321523,29.4623869 C23.2324418,29.4623869 26.1781333,32.4080784 26.1781333,36.0083679 C26.1781333,39.6086574 23.2324418,42.5543489 19.6321523,42.5543489 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(66.000000, 66.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_VALIDATE_PIN":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-pin-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-810.000000, -897.000000)">
                                <g id="ic-pin-chevron" transform="translate(810.000000, 897.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="visibility_black_24dp" transform="translate(8.000000, 8.000000)">
                                        <polygon id="Path" points="0 0 76 0 76 76 0 76"></polygon>
                                        <path d="M38,12.6666667 C20.7272727,12.6666667 5.97636364,23.410303 0,38.5757576 C5.97636364,53.7412121 20.7272727,64.4848485 38,64.4848485 C55.2727273,64.4848485 70.0236364,53.7412121 76,38.5757576 C70.0236364,23.410303 55.2727273,12.6666667 38,12.6666667 Z M38,55.8484848 C28.4654545,55.8484848 20.7272727,48.110303 20.7272727,38.5757576 C20.7272727,29.0412121 28.4654545,21.3030303 38,21.3030303 C47.5345455,21.3030303 55.2727273,29.0412121 55.2727273,38.5757576 C55.2727273,48.110303 47.5345455,55.8484848 38,55.8484848 Z M38,28.2121212 C32.2654545,28.2121212 27.6363636,32.8412121 27.6363636,38.5757576 C27.6363636,44.310303 32.2654545,48.9393939 38,48.9393939 C43.7345455,48.9393939 48.3636364,44.310303 48.3636364,38.5757576 C48.3636364,32.8412121 43.7345455,28.2121212 38,28.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                        <g id="Group" transform="translate(52.000000, 52.000000)" fill-rule="nonzero">
                                            <g>
                                                <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                                <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PUT_PIN":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-pin-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1072.000000, -897.000000)">
                                <g id="ic-pin-in-progress" transform="translate(1072.000000, 897.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="visibility_black_24dp" transform="translate(8.000000, 8.000000)">
                                        <polygon id="Path" points="0 0 76 0 76 76 0 76"></polygon>
                                        <path d="M38,12.6666667 C20.7272727,12.6666667 5.97636364,23.410303 0,38.5757576 C5.97636364,53.7412121 20.7272727,64.4848485 38,64.4848485 C55.2727273,64.4848485 70.0236364,53.7412121 76,38.5757576 C70.0236364,23.410303 55.2727273,12.6666667 38,12.6666667 Z M38,55.8484848 C28.4654545,55.8484848 20.7272727,48.110303 20.7272727,38.5757576 C20.7272727,29.0412121 28.4654545,21.3030303 38,21.3030303 C47.5345455,21.3030303 55.2727273,29.0412121 55.2727273,38.5757576 C55.2727273,48.110303 47.5345455,55.8484848 38,55.8484848 Z M38,28.2121212 C32.2654545,28.2121212 27.6363636,32.8412121 27.6363636,38.5757576 C27.6363636,44.310303 32.2654545,48.9393939 38,48.9393939 C43.7345455,48.9393939 48.3636364,44.310303 48.3636364,38.5757576 C48.3636364,32.8412121 43.7345455,28.2121212 38,28.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                        <g id="Group" transform="translate(52.000000, 52.000000)" fill-rule="nonzero">
                                            <g>
                                                <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                                <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                    <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                    <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_PIN":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-pin-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -897.000000)">
                                <g id="ic-pin-plus" transform="translate(559.000000, 897.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="visibility_black_24dp" transform="translate(8.000000, 8.000000)">
                                        <polygon id="Path" points="0 0 76 0 76 76 0 76"></polygon>
                                        <path d="M38,12.6666667 C20.7272727,12.6666667 5.97636364,23.410303 0,38.5757576 C5.97636364,53.7412121 20.7272727,64.4848485 38,64.4848485 C55.2727273,64.4848485 70.0236364,53.7412121 76,38.5757576 C70.0236364,23.410303 55.2727273,12.6666667 38,12.6666667 Z M38,55.8484848 C28.4654545,55.8484848 20.7272727,48.110303 20.7272727,38.5757576 C20.7272727,29.0412121 28.4654545,21.3030303 38,21.3030303 C47.5345455,21.3030303 55.2727273,29.0412121 55.2727273,38.5757576 C55.2727273,48.110303 47.5345455,55.8484848 38,55.8484848 Z M38,28.2121212 C32.2654545,28.2121212 27.6363636,32.8412121 27.6363636,38.5757576 C27.6363636,44.310303 32.2654545,48.9393939 38,48.9393939 C43.7345455,48.9393939 48.3636364,44.310303 48.3636364,38.5757576 C48.3636364,32.8412121 43.7345455,28.2121212 38,28.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                        <g id="Group" transform="translate(52.000000, 52.000000)" fill-rule="nonzero">
                                            <g id="plus">
                                                <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                                <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_ACCOUNT_PARTIES":
            case "GET_ACCOUNT_DETAILS_PAYMENT_NETWORKS_FDX":
            case "GET_ACCOUNTS_STATEMENTS_FDX":
            case "GET_ACCOUNTS_STATEMENT_DETAILS_FDX":
            case "GET_ACCOUNTS_TRANSACTION_IMAGE_FDX":
            case "GET_ACCOUNTS_TRANSACTION_IMAGE_FDX":
            case "GET_ACCOUNT_DETAILS_CONTACT_FDX":
            case "GET_ACCOUNT_DETAILS_FDX":
            case "GET_ACCOUNT_DETAILS":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-810.000000, -1147.000000)">
                                <g id="ic-account-chevron" transform="translate(810.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_ACCOUNT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-minus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-309.000000, -1147.000000)">
                                <g id="ic-account-minus" transform="translate(309.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_ACCOUNT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -1147.000000)">
                                <g id="ic-account-plus" transform="translate(559.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PUT_ACCOUNT_BLOCK":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1062.000000, -1147.000000)">
                                <g id="ic-account-in-progress" transform="translate(1062.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PUT_ACCOUNT_UNBLOCK":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1062.000000, -1147.000000)">
                                <g id="ic-account-in-progress" transform="translate(1062.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_ACCOUNTS_FDX":
            case "GET_ACCOUNTS":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-810.000000, -1147.000000)">
                                <g id="ic-account-chevron" transform="translate(810.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_ACCOUNT_TRANSACTIONS_FDX":
            case "GET_ACCOUNT_TRANSACTIONS":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-account-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-810.000000, -1147.000000)">
                                <g id="ic-account-chevron" transform="translate(810.000000, 1147.000000)">
                                    <g id="account_balance_wallet-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M41.6666667,66.6666667 L41.6666667,33.3333333 C41.6666667,28.75 45.375,25 50,25 L87.5,25 L87.5,20.8333333 C87.5,16.25 83.75,12.5 79.1666667,12.5 L20.8333333,12.5 C16.2083333,12.5 12.5,16.25 12.5,20.8333333 L12.5,79.1666667 C12.5,83.75 16.2083333,87.5 20.8333333,87.5 L79.1666667,87.5 C83.75,87.5 87.5,83.75 87.5,79.1666667 L87.5,75 L50,75 C45.375,75 41.6666667,71.25 41.6666667,66.6666667 Z M54.1666667,33.3333333 C51.875,33.3333333 50,35.2083333 50,37.5 L50,62.5 C50,64.7916667 51.875,66.6666667 54.1666667,66.6666667 L91.6666667,66.6666667 L91.6666667,33.3333333 L54.1666667,33.3333333 Z M66.6666667,56.25 C63.2083333,56.25 60.4166667,53.4583333 60.4166667,50 C60.4166667,46.5416667 63.2083333,43.75 66.6666667,43.75 C70.125,43.75 72.9166667,46.5416667 72.9166667,50 C72.9166667,53.4583333 70.125,56.25 66.6666667,56.25 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PATCH_PAYEE_FDX":
            case "PUT_BENEFICIARIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-beneficiaries-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1072.000000, -1397.000000)">
                                <g id="ic-beneficiaries-in-progress" transform="translate(1072.000000, 1397.000000)">
                                    <g id="family_restroom-24px">
                                        <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                        <path d="M65.6,18.8 C65.6,14.471 69.071,11 73.4,11 C77.729,11 81.2,14.471 81.2,18.8 C81.2,23.129 77.729,26.6 73.4,26.6 C69.071,26.6 65.6,23.129 65.6,18.8 Z M81.2,85.1 L81.2,65.6 L85.529,65.6 C88.181,65.6 90.053,62.987 89.234,60.452 L81.044,35.843 C79.952,32.645 76.988,30.5 73.634,30.5 L73.166,30.5 C69.812,30.5 66.809,32.645 65.756,35.843 L62.402,45.905 C66.614,48.245 69.5,52.652 69.5,57.8 L69.5,89 L77.3,89 C79.445,89 81.2,87.245 81.2,85.1 Z M51.95,48.05 C55.187,48.05 57.8,45.437 57.8,42.2 C57.8,38.963 55.187,36.35 51.95,36.35 C48.713,36.35 46.1,38.963 46.1,42.2 C46.1,45.437 48.713,48.05 51.95,48.05 Z M24.65,26.6 C28.979,26.6 32.45,23.129 32.45,18.8 C32.45,14.471 28.979,11 24.65,11 C20.321,11 16.85,14.471 16.85,18.8 C16.85,23.129 20.321,26.6 24.65,26.6 Z M32.45,85.1 L32.45,61.7 L34.4,61.7 C36.545,61.7 38.3,59.945 38.3,57.8 L38.3,38.3 C38.3,34.01 34.79,30.5 30.5,30.5 L18.8,30.5 C14.51,30.5 11,34.01 11,38.3 L11,57.8 C11,59.945 12.755,61.7 14.9,61.7 L16.85,61.7 L16.85,85.1 C16.85,87.245 18.605,89 20.75,89 L28.55,89 C30.695,89 32.45,87.245 32.45,85.1 Z M42.2,57.8 L42.2,69.5 C42.2,71.645 43.955,73.4 46.1,73.4 L46.1,73.4 L46.1,85.1 C46.1,87.245 47.855,89 50,89 L53.9,89 C56.045,89 57.8,87.245 57.8,85.1 L57.8,73.4 L57.8,73.4 C59.945,73.4 61.7,71.645 61.7,69.5 L61.7,57.8 C61.7,54.602 59.048,51.95 55.85,51.95 L48.05,51.95 C44.852,51.95 42.2,54.602 42.2,57.8" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_PAYEES_FDX":
            case "POST_BENEFICIARIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-beneficiaries-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -1397.000000)">
                                <g id="ic-beneficiaries-plus" transform="translate(559.000000, 1397.000000)">
                                    <g id="family_restroom-24px">
                                        <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                        <path d="M65.6,18.8 C65.6,14.471 69.071,11 73.4,11 C77.729,11 81.2,14.471 81.2,18.8 C81.2,23.129 77.729,26.6 73.4,26.6 C69.071,26.6 65.6,23.129 65.6,18.8 Z M81.2,85.1 L81.2,65.6 L85.529,65.6 C88.181,65.6 90.053,62.987 89.234,60.452 L81.044,35.843 C79.952,32.645 76.988,30.5 73.634,30.5 L73.166,30.5 C69.812,30.5 66.809,32.645 65.756,35.843 L62.402,45.905 C66.614,48.245 69.5,52.652 69.5,57.8 L69.5,89 L77.3,89 C79.445,89 81.2,87.245 81.2,85.1 Z M51.95,48.05 C55.187,48.05 57.8,45.437 57.8,42.2 C57.8,38.963 55.187,36.35 51.95,36.35 C48.713,36.35 46.1,38.963 46.1,42.2 C46.1,45.437 48.713,48.05 51.95,48.05 Z M24.65,26.6 C28.979,26.6 32.45,23.129 32.45,18.8 C32.45,14.471 28.979,11 24.65,11 C20.321,11 16.85,14.471 16.85,18.8 C16.85,23.129 20.321,26.6 24.65,26.6 Z M32.45,85.1 L32.45,61.7 L34.4,61.7 C36.545,61.7 38.3,59.945 38.3,57.8 L38.3,38.3 C38.3,34.01 34.79,30.5 30.5,30.5 L18.8,30.5 C14.51,30.5 11,34.01 11,38.3 L11,57.8 C11,59.945 12.755,61.7 14.9,61.7 L16.85,61.7 L16.85,85.1 C16.85,87.245 18.605,89 20.75,89 L28.55,89 C30.695,89 32.45,87.245 32.45,85.1 Z M42.2,57.8 L42.2,69.5 C42.2,71.645 43.955,73.4 46.1,73.4 L46.1,73.4 L46.1,85.1 C46.1,87.245 47.855,89 50,89 L53.9,89 C56.045,89 57.8,87.245 57.8,85.1 L57.8,73.4 L57.8,73.4 C59.945,73.4 61.7,71.645 61.7,69.5 L61.7,57.8 C61.7,54.602 59.048,51.95 55.85,51.95 L48.05,51.95 C44.852,51.95 42.2,54.602 42.2,57.8" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_PAYEE_FDX":
            case "GET_PAYEES_FDX":
            case "GET_BENEFICIARIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-beneficiaries-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-812.000000, -1397.000000)">
                                <g id="ic-beneficiaries-chevron" transform="translate(812.000000, 1397.000000)">
                                    <g id="family_restroom-24px">
                                        <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                        <path d="M65.6,18.8 C65.6,14.471 69.071,11 73.4,11 C77.729,11 81.2,14.471 81.2,18.8 C81.2,23.129 77.729,26.6 73.4,26.6 C69.071,26.6 65.6,23.129 65.6,18.8 Z M81.2,85.1 L81.2,65.6 L85.529,65.6 C88.181,65.6 90.053,62.987 89.234,60.452 L81.044,35.843 C79.952,32.645 76.988,30.5 73.634,30.5 L73.166,30.5 C69.812,30.5 66.809,32.645 65.756,35.843 L62.402,45.905 C66.614,48.245 69.5,52.652 69.5,57.8 L69.5,89 L77.3,89 C79.445,89 81.2,87.245 81.2,85.1 Z M51.95,48.05 C55.187,48.05 57.8,45.437 57.8,42.2 C57.8,38.963 55.187,36.35 51.95,36.35 C48.713,36.35 46.1,38.963 46.1,42.2 C46.1,45.437 48.713,48.05 51.95,48.05 Z M24.65,26.6 C28.979,26.6 32.45,23.129 32.45,18.8 C32.45,14.471 28.979,11 24.65,11 C20.321,11 16.85,14.471 16.85,18.8 C16.85,23.129 20.321,26.6 24.65,26.6 Z M32.45,85.1 L32.45,61.7 L34.4,61.7 C36.545,61.7 38.3,59.945 38.3,57.8 L38.3,38.3 C38.3,34.01 34.79,30.5 30.5,30.5 L18.8,30.5 C14.51,30.5 11,34.01 11,38.3 L11,57.8 C11,59.945 12.755,61.7 14.9,61.7 L16.85,61.7 L16.85,85.1 C16.85,87.245 18.605,89 20.75,89 L28.55,89 C30.695,89 32.45,87.245 32.45,85.1 Z M42.2,57.8 L42.2,69.5 C42.2,71.645 43.955,73.4 46.1,73.4 L46.1,73.4 L46.1,85.1 C46.1,87.245 47.855,89 50,89 L53.9,89 C56.045,89 57.8,87.245 57.8,85.1 L57.8,73.4 L57.8,73.4 C59.945,73.4 61.7,71.645 61.7,69.5 L61.7,57.8 C61.7,54.602 59.048,51.95 55.85,51.95 L48.05,51.95 C44.852,51.95 42.2,54.602 42.2,57.8" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_PAYEE_FDX":
            case "DELETE_BENEFICIARIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-beneficiaries-minus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-309.000000, -1397.000000)">
                                <g id="ic-beneficiaries-minus" transform="translate(309.000000, 1397.000000)">
                                    <g id="family_restroom-24px">
                                        <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                        <path d="M65.6,18.8 C65.6,14.471 69.071,11 73.4,11 C77.729,11 81.2,14.471 81.2,18.8 C81.2,23.129 77.729,26.6 73.4,26.6 C69.071,26.6 65.6,23.129 65.6,18.8 Z M81.2,85.1 L81.2,65.6 L85.529,65.6 C88.181,65.6 90.053,62.987 89.234,60.452 L81.044,35.843 C79.952,32.645 76.988,30.5 73.634,30.5 L73.166,30.5 C69.812,30.5 66.809,32.645 65.756,35.843 L62.402,45.905 C66.614,48.245 69.5,52.652 69.5,57.8 L69.5,89 L77.3,89 C79.445,89 81.2,87.245 81.2,85.1 Z M51.95,48.05 C55.187,48.05 57.8,45.437 57.8,42.2 C57.8,38.963 55.187,36.35 51.95,36.35 C48.713,36.35 46.1,38.963 46.1,42.2 C46.1,45.437 48.713,48.05 51.95,48.05 Z M24.65,26.6 C28.979,26.6 32.45,23.129 32.45,18.8 C32.45,14.471 28.979,11 24.65,11 C20.321,11 16.85,14.471 16.85,18.8 C16.85,23.129 20.321,26.6 24.65,26.6 Z M32.45,85.1 L32.45,61.7 L34.4,61.7 C36.545,61.7 38.3,59.945 38.3,57.8 L38.3,38.3 C38.3,34.01 34.79,30.5 30.5,30.5 L18.8,30.5 C14.51,30.5 11,34.01 11,38.3 L11,57.8 C11,59.945 12.755,61.7 14.9,61.7 L16.85,61.7 L16.85,85.1 C16.85,87.245 18.605,89 20.75,89 L28.55,89 C30.695,89 32.45,87.245 32.45,85.1 Z M42.2,57.8 L42.2,69.5 C42.2,71.645 43.955,73.4 46.1,73.4 L46.1,73.4 L46.1,85.1 C46.1,87.245 47.855,89 50,89 L53.9,89 C56.045,89 57.8,87.245 57.8,85.1 L57.8,73.4 L57.8,73.4 C59.945,73.4 61.7,71.645 61.7,69.5 L61.7,57.8 C61.7,54.602 59.048,51.95 55.85,51.95 L48.05,51.95 C44.852,51.95 42.2,54.602 42.2,57.8" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PUT_PROFILE":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>icon-profile-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1064.000000, -1647.000000)">
                                <g id="icon-profile-in-progress" transform="translate(1064.000000, 1647.000000)">
                                    <g id="account_circle-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M50,8.33333333 C27,8.33333333 8.33333333,27 8.33333333,50 C8.33333333,73 27,91.6666667 50,91.6666667 C73,91.6666667 91.6666667,73 91.6666667,50 C91.6666667,27 73,8.33333333 50,8.33333333 Z M50,20.8333333 C56.9166667,20.8333333 62.5,26.4166667 62.5,33.3333333 C62.5,40.25 56.9166667,45.8333333 50,45.8333333 C43.0833333,45.8333333 37.5,40.25 37.5,33.3333333 C37.5,26.4166667 43.0833333,20.8333333 50,20.8333333 Z M50,80 C39.5833333,80 30.375,74.6666667 25,66.5833333 C25.125,58.2916667 41.6666667,53.75 50,53.75 C58.2916667,53.75 74.875,58.2916667 75,66.5833333 C69.625,74.6666667 60.4166667,80 50,80 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_PROFILE":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>icon-profile-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -1647.000000)">
                                <g id="icon-profile-plus" transform="translate(559.000000, 1647.000000)">
                                    <g id="account_circle-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M50,8.33333333 C27,8.33333333 8.33333333,27 8.33333333,50 C8.33333333,73 27,91.6666667 50,91.6666667 C73,91.6666667 91.6666667,73 91.6666667,50 C91.6666667,27 73,8.33333333 50,8.33333333 Z M50,20.8333333 C56.9166667,20.8333333 62.5,26.4166667 62.5,33.3333333 C62.5,40.25 56.9166667,45.8333333 50,45.8333333 C43.0833333,45.8333333 37.5,40.25 37.5,33.3333333 C37.5,26.4166667 43.0833333,20.8333333 50,20.8333333 Z M50,80 C39.5833333,80 30.375,74.6666667 25,66.5833333 C25.125,58.2916667 41.6666667,53.75 50,53.75 C58.2916667,53.75 74.875,58.2916667 75,66.5833333 C69.625,74.6666667 60.4166667,80 50,80 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PUT_PROFILE_RESET":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>icon-profile-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1064.000000, -1647.000000)">
                                <g id="icon-profile-in-progress" transform="translate(1064.000000, 1647.000000)">
                                    <g id="account_circle-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M50,8.33333333 C27,8.33333333 8.33333333,27 8.33333333,50 C8.33333333,73 27,91.6666667 50,91.6666667 C73,91.6666667 91.6666667,73 91.6666667,50 C91.6666667,27 73,8.33333333 50,8.33333333 Z M50,20.8333333 C56.9166667,20.8333333 62.5,26.4166667 62.5,33.3333333 C62.5,40.25 56.9166667,45.8333333 50,45.8333333 C43.0833333,45.8333333 37.5,40.25 37.5,33.3333333 C37.5,26.4166667 43.0833333,20.8333333 50,20.8333333 Z M50,80 C39.5833333,80 30.375,74.6666667 25,66.5833333 C25.125,58.2916667 41.6666667,53.75 50,53.75 C58.2916667,53.75 74.875,58.2916667 75,66.5833333 C69.625,74.6666667 60.4166667,80 50,80 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_PROFILE":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>icon-profile-minus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-309.000000, -1647.000000)">
                                <g id="icon-profile-minus" transform="translate(309.000000, 1647.000000)">
                                    <g id="account_circle-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M50,8.33333333 C27,8.33333333 8.33333333,27 8.33333333,50 C8.33333333,73 27,91.6666667 50,91.6666667 C73,91.6666667 91.6666667,73 91.6666667,50 C91.6666667,27 73,8.33333333 50,8.33333333 Z M50,20.8333333 C56.9166667,20.8333333 62.5,26.4166667 62.5,33.3333333 C62.5,40.25 56.9166667,45.8333333 50,45.8333333 C43.0833333,45.8333333 37.5,40.25 37.5,33.3333333 C37.5,26.4166667 43.0833333,20.8333333 50,20.8333333 Z M50,80 C39.5833333,80 30.375,74.6666667 25,66.5833333 C25.125,58.2916667 41.6666667,53.75 50,53.75 C58.2916667,53.75 74.875,58.2916667 75,66.5833333 C69.625,74.6666667 60.4166667,80 50,80 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_PROFILE_KYC":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>icon-profile-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1064.000000, -1647.000000)">
                                <g id="icon-profile-in-progress" transform="translate(1064.000000, 1647.000000)">
                                    <g id="account_circle-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M50,8.33333333 C27,8.33333333 8.33333333,27 8.33333333,50 C8.33333333,73 27,91.6666667 50,91.6666667 C73,91.6666667 91.6666667,73 91.6666667,50 C91.6666667,27 73,8.33333333 50,8.33333333 Z M50,20.8333333 C56.9166667,20.8333333 62.5,26.4166667 62.5,33.3333333 C62.5,40.25 56.9166667,45.8333333 50,45.8333333 C43.0833333,45.8333333 37.5,40.25 37.5,33.3333333 C37.5,26.4166667 43.0833333,20.8333333 50,20.8333333 Z M50,80 C39.5833333,80 30.375,74.6666667 25,66.5833333 C25.125,58.2916667 41.6666667,53.75 50,53.75 C58.2916667,53.75 74.875,58.2916667 75,66.5833333 C69.625,74.6666667 60.4166667,80 50,80 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_PROFILE":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>icon-profile-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -1647.000000)">
                                <g id="icon-profile-chevron" transform="translate(809.000000, 1647.000000)">
                                    <g id="account_circle-24px">
                                        <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                        <path d="M50,8.33333333 C27,8.33333333 8.33333333,27 8.33333333,50 C8.33333333,73 27,91.6666667 50,91.6666667 C73,91.6666667 91.6666667,73 91.6666667,50 C91.6666667,27 73,8.33333333 50,8.33333333 Z M50,20.8333333 C56.9166667,20.8333333 62.5,26.4166667 62.5,33.3333333 C62.5,40.25 56.9166667,45.8333333 50,45.8333333 C43.0833333,45.8333333 37.5,40.25 37.5,33.3333333 C37.5,26.4166667 43.0833333,20.8333333 50,20.8333333 Z M50,80 C39.5833333,80 30.375,74.6666667 25,66.5833333 C25.125,58.2916667 41.6666667,53.75 50,53.75 C58.2916667,53.75 74.875,58.2916667 75,66.5833333 C69.625,74.6666667 60.4166667,80 50,80 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_REWARDS_ENROLL":
            case "POST_REWARDS_REDEMPTION":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-rewards-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -3397.000000)">
                                <g id="ic-rewards-plus" transform="translate(559.000000, 3397.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="card_giftcard_black_24dp" transform="translate(6.000000, 6.000000)">
                                        <polygon id="Path" points="0 0 88 0 88 88 0 88"></polygon>
                                        <path d="M73.3333333,22 L65.34,22 C65.7433333,20.8633333 66,19.6166667 66,18.3333333 C66,12.2466667 61.0866667,7.33333333 55,7.33333333 C51.15,7.33333333 47.8133333,9.31333333 45.8333333,12.2833333 L44,14.74 L42.1666667,12.2466667 C40.1866667,9.31333333 36.85,7.33333333 33,7.33333333 C26.9133333,7.33333333 22,12.2466667 22,18.3333333 C22,19.6166667 22.2566667,20.8633333 22.66,22 L14.6666667,22 C10.5966667,22 7.37,25.2633333 7.37,29.3333333 L7.33333333,69.6666667 C7.33333333,73.7366667 10.5966667,77 14.6666667,77 L73.3333333,77 C77.4033333,77 80.6666667,73.7366667 80.6666667,69.6666667 L80.6666667,29.3333333 C80.6666667,25.2633333 77.4033333,22 73.3333333,22 Z M55,14.6666667 C57.0166667,14.6666667 58.6666667,16.3166667 58.6666667,18.3333333 C58.6666667,20.35 57.0166667,22 55,22 C52.9833333,22 51.3333333,20.35 51.3333333,18.3333333 C51.3333333,16.3166667 52.9833333,14.6666667 55,14.6666667 Z M33,14.6666667 C35.0166667,14.6666667 36.6666667,16.3166667 36.6666667,18.3333333 C36.6666667,20.35 35.0166667,22 33,22 C30.9833333,22 29.3333333,20.35 29.3333333,18.3333333 C29.3333333,16.3166667 30.9833333,14.6666667 33,14.6666667 Z M73.3333333,69.6666667 L14.6666667,69.6666667 L14.6666667,62.3333333 L73.3333333,62.3333333 L73.3333333,69.6666667 Z M73.3333333,51.3333333 L14.6666667,51.3333333 L14.6666667,33 C14.6666667,30.9833333 16.3166667,29.3333333 18.3333333,29.3333333 L33.2933333,29.3333333 L27.8666667,36.74 C26.6566667,38.39 27.0233333,40.7 28.6733333,41.8733333 C30.2866667,43.0466667 32.5966667,42.68 33.77,41.0666667 L44,27.1333333 L54.23,41.0666667 C55.4033333,42.68 57.7133333,43.0466667 59.3266667,41.8733333 C60.9766667,40.7 61.3433333,38.39 60.1333333,36.74 L54.7066667,29.3333333 L69.6666667,29.3333333 C71.6833333,29.3333333 73.3333333,30.9833333 73.3333333,33 L73.3333333,51.3333333 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_REWARDS_ACCOUNT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-rewards-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -3397.000000)">
                                <g id="ic-rewards-plus" transform="translate(559.000000, 3397.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="card_giftcard_black_24dp" transform="translate(6.000000, 6.000000)">
                                        <polygon id="Path" points="0 0 88 0 88 88 0 88"></polygon>
                                        <path d="M73.3333333,22 L65.34,22 C65.7433333,20.8633333 66,19.6166667 66,18.3333333 C66,12.2466667 61.0866667,7.33333333 55,7.33333333 C51.15,7.33333333 47.8133333,9.31333333 45.8333333,12.2833333 L44,14.74 L42.1666667,12.2466667 C40.1866667,9.31333333 36.85,7.33333333 33,7.33333333 C26.9133333,7.33333333 22,12.2466667 22,18.3333333 C22,19.6166667 22.2566667,20.8633333 22.66,22 L14.6666667,22 C10.5966667,22 7.37,25.2633333 7.37,29.3333333 L7.33333333,69.6666667 C7.33333333,73.7366667 10.5966667,77 14.6666667,77 L73.3333333,77 C77.4033333,77 80.6666667,73.7366667 80.6666667,69.6666667 L80.6666667,29.3333333 C80.6666667,25.2633333 77.4033333,22 73.3333333,22 Z M55,14.6666667 C57.0166667,14.6666667 58.6666667,16.3166667 58.6666667,18.3333333 C58.6666667,20.35 57.0166667,22 55,22 C52.9833333,22 51.3333333,20.35 51.3333333,18.3333333 C51.3333333,16.3166667 52.9833333,14.6666667 55,14.6666667 Z M33,14.6666667 C35.0166667,14.6666667 36.6666667,16.3166667 36.6666667,18.3333333 C36.6666667,20.35 35.0166667,22 33,22 C30.9833333,22 29.3333333,20.35 29.3333333,18.3333333 C29.3333333,16.3166667 30.9833333,14.6666667 33,14.6666667 Z M73.3333333,69.6666667 L14.6666667,69.6666667 L14.6666667,62.3333333 L73.3333333,62.3333333 L73.3333333,69.6666667 Z M73.3333333,51.3333333 L14.6666667,51.3333333 L14.6666667,33 C14.6666667,30.9833333 16.3166667,29.3333333 18.3333333,29.3333333 L33.2933333,29.3333333 L27.8666667,36.74 C26.6566667,38.39 27.0233333,40.7 28.6733333,41.8733333 C30.2866667,43.0466667 32.5966667,42.68 33.77,41.0666667 L44,27.1333333 L54.23,41.0666667 C55.4033333,42.68 57.7133333,43.0466667 59.3266667,41.8733333 C60.9766667,40.7 61.3433333,38.39 60.1333333,36.74 L54.7066667,29.3333333 L69.6666667,29.3333333 C71.6833333,29.3333333 73.3333333,30.9833333 73.3333333,33 L73.3333333,51.3333333 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_REWARD_PROGRAMS_FDX":
            case "GET_REWARD_PROGRAM_DETAILS_FDX":
            case "GET_REWARD_PROGRAM_DETAILS_CATEGORIES_FDX":
            case "GET_REWARDS_ACCOUNT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-rewards-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -3397.000000)">
                                <g id="ic-rewards-plus" transform="translate(559.000000, 3397.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="card_giftcard_black_24dp" transform="translate(6.000000, 6.000000)">
                                        <polygon id="Path" points="0 0 88 0 88 88 0 88"></polygon>
                                        <path d="M73.3333333,22 L65.34,22 C65.7433333,20.8633333 66,19.6166667 66,18.3333333 C66,12.2466667 61.0866667,7.33333333 55,7.33333333 C51.15,7.33333333 47.8133333,9.31333333 45.8333333,12.2833333 L44,14.74 L42.1666667,12.2466667 C40.1866667,9.31333333 36.85,7.33333333 33,7.33333333 C26.9133333,7.33333333 22,12.2466667 22,18.3333333 C22,19.6166667 22.2566667,20.8633333 22.66,22 L14.6666667,22 C10.5966667,22 7.37,25.2633333 7.37,29.3333333 L7.33333333,69.6666667 C7.33333333,73.7366667 10.5966667,77 14.6666667,77 L73.3333333,77 C77.4033333,77 80.6666667,73.7366667 80.6666667,69.6666667 L80.6666667,29.3333333 C80.6666667,25.2633333 77.4033333,22 73.3333333,22 Z M55,14.6666667 C57.0166667,14.6666667 58.6666667,16.3166667 58.6666667,18.3333333 C58.6666667,20.35 57.0166667,22 55,22 C52.9833333,22 51.3333333,20.35 51.3333333,18.3333333 C51.3333333,16.3166667 52.9833333,14.6666667 55,14.6666667 Z M33,14.6666667 C35.0166667,14.6666667 36.6666667,16.3166667 36.6666667,18.3333333 C36.6666667,20.35 35.0166667,22 33,22 C30.9833333,22 29.3333333,20.35 29.3333333,18.3333333 C29.3333333,16.3166667 30.9833333,14.6666667 33,14.6666667 Z M73.3333333,69.6666667 L14.6666667,69.6666667 L14.6666667,62.3333333 L73.3333333,62.3333333 L73.3333333,69.6666667 Z M73.3333333,51.3333333 L14.6666667,51.3333333 L14.6666667,33 C14.6666667,30.9833333 16.3166667,29.3333333 18.3333333,29.3333333 L33.2933333,29.3333333 L27.8666667,36.74 C26.6566667,38.39 27.0233333,40.7 28.6733333,41.8733333 C30.2866667,43.0466667 32.5966667,42.68 33.77,41.0666667 L44,27.1333333 L54.23,41.0666667 C55.4033333,42.68 57.7133333,43.0466667 59.3266667,41.8733333 C60.9766667,40.7 61.3433333,38.39 60.1333333,36.74 L54.7066667,29.3333333 L69.6666667,29.3333333 C71.6833333,29.3333333 73.3333333,30.9833333 73.3333333,33 L73.3333333,51.3333333 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(66.000000, 66.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_DEPOSIT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-deposits-minus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-309.000000, -1897.000000)">
                                <g id="ic-deposits-minus" transform="translate(309.000000, 1897.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <g id="money_black_24dp" transform="translate(10.000000, 10.000000)">
                                        <polygon id="Path" points="0 0 80 0 80 80 0 80"></polygon>
                                        <path d="M50,53.3333333 L60,53.3333333 C61.8333333,53.3333333 63.3333333,51.8333333 63.3333333,50 L63.3333333,30 C63.3333333,28.1666667 61.8333333,26.6666667 60,26.6666667 L50,26.6666667 C48.1666667,26.6666667 46.6666667,28.1666667 46.6666667,30 L46.6666667,50 C46.6666667,51.8333333 48.1666667,53.3333333 50,53.3333333 Z M53.3333333,33.3333333 L56.6666667,33.3333333 L56.6666667,46.6666667 L53.3333333,46.6666667 L53.3333333,33.3333333 Z M30,53.3333333 L40,53.3333333 C41.8333333,53.3333333 43.3333333,51.8333333 43.3333333,50 L43.3333333,30 C43.3333333,28.1666667 41.8333333,26.6666667 40,26.6666667 L30,26.6666667 C28.1666667,26.6666667 26.6666667,28.1666667 26.6666667,30 L26.6666667,50 C26.6666667,51.8333333 28.1666667,53.3333333 30,53.3333333 Z M33.3333333,33.3333333 L36.6666667,33.3333333 L36.6666667,46.6666667 L33.3333333,46.6666667 L33.3333333,33.3333333 Z M20,26.6666667 C18.1666667,26.6666667 16.6666667,28.1666667 16.6666667,30 L16.6666667,50 C16.6666667,51.8333333 18.1666667,53.3333333 20,53.3333333 C21.8333333,53.3333333 23.3333333,51.8333333 23.3333333,50 L23.3333333,30 C23.3333333,28.1666667 21.8333333,26.6666667 20,26.6666667 Z M6.66666667,20 L6.66666667,60 C6.66666667,63.6666667 9.66666667,66.6666667 13.3333333,66.6666667 L66.6666667,66.6666667 C70.3333333,66.6666667 73.3333333,63.6666667 73.3333333,60 L73.3333333,20 C73.3333333,16.3333333 70.3333333,13.3333333 66.6666667,13.3333333 L13.3333333,13.3333333 C9.66666667,13.3333333 6.66666667,16.3333333 6.66666667,20 Z M63.3333333,60 L16.6666667,60 C14.8333333,60 13.3333333,58.5 13.3333333,56.6666667 L13.3333333,23.3333333 C13.3333333,21.5 14.8333333,20 16.6666667,20 L63.3333333,20 C65.1666667,20 66.6666667,21.5 66.6666667,23.3333333 L66.6666667,56.6666667 C66.6666667,58.5 65.1666667,60 63.3333333,60 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_DEPOSIT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-deposits-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -1897.000000)">
                                <g id="ic-deposits-plus" transform="translate(559.000000, 1897.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <g id="money_black_24dp" transform="translate(10.000000, 10.000000)">
                                        <polygon id="Path" points="0 0 80 0 80 80 0 80"></polygon>
                                        <path d="M50,53.3333333 L60,53.3333333 C61.8333333,53.3333333 63.3333333,51.8333333 63.3333333,50 L63.3333333,30 C63.3333333,28.1666667 61.8333333,26.6666667 60,26.6666667 L50,26.6666667 C48.1666667,26.6666667 46.6666667,28.1666667 46.6666667,30 L46.6666667,50 C46.6666667,51.8333333 48.1666667,53.3333333 50,53.3333333 Z M53.3333333,33.3333333 L56.6666667,33.3333333 L56.6666667,46.6666667 L53.3333333,46.6666667 L53.3333333,33.3333333 Z M30,53.3333333 L40,53.3333333 C41.8333333,53.3333333 43.3333333,51.8333333 43.3333333,50 L43.3333333,30 C43.3333333,28.1666667 41.8333333,26.6666667 40,26.6666667 L30,26.6666667 C28.1666667,26.6666667 26.6666667,28.1666667 26.6666667,30 L26.6666667,50 C26.6666667,51.8333333 28.1666667,53.3333333 30,53.3333333 Z M33.3333333,33.3333333 L36.6666667,33.3333333 L36.6666667,46.6666667 L33.3333333,46.6666667 L33.3333333,33.3333333 Z M20,26.6666667 C18.1666667,26.6666667 16.6666667,28.1666667 16.6666667,30 L16.6666667,50 C16.6666667,51.8333333 18.1666667,53.3333333 20,53.3333333 C21.8333333,53.3333333 23.3333333,51.8333333 23.3333333,50 L23.3333333,30 C23.3333333,28.1666667 21.8333333,26.6666667 20,26.6666667 Z M6.66666667,20 L6.66666667,60 C6.66666667,63.6666667 9.66666667,66.6666667 13.3333333,66.6666667 L66.6666667,66.6666667 C70.3333333,66.6666667 73.3333333,63.6666667 73.3333333,60 L73.3333333,20 C73.3333333,16.3333333 70.3333333,13.3333333 66.6666667,13.3333333 L13.3333333,13.3333333 C9.66666667,13.3333333 6.66666667,16.3333333 6.66666667,20 Z M63.3333333,60 L16.6666667,60 C14.8333333,60 13.3333333,58.5 13.3333333,56.6666667 L13.3333333,23.3333333 C13.3333333,21.5 14.8333333,20 16.6666667,20 L63.3333333,20 C65.1666667,20 66.6666667,21.5 66.6666667,23.3333333 L66.6666667,56.6666667 C66.6666667,58.5 65.1666667,60 63.3333333,60 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_DEPOSIT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-deposits-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -1897.000000)">
                                <g id="ic-deposits-chevron" transform="translate(809.000000, 1897.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <g id="money_black_24dp" transform="translate(10.000000, 10.000000)">
                                        <polygon id="Path" points="0 0 80 0 80 80 0 80"></polygon>
                                        <path d="M50,53.3333333 L60,53.3333333 C61.8333333,53.3333333 63.3333333,51.8333333 63.3333333,50 L63.3333333,30 C63.3333333,28.1666667 61.8333333,26.6666667 60,26.6666667 L50,26.6666667 C48.1666667,26.6666667 46.6666667,28.1666667 46.6666667,30 L46.6666667,50 C46.6666667,51.8333333 48.1666667,53.3333333 50,53.3333333 Z M53.3333333,33.3333333 L56.6666667,33.3333333 L56.6666667,46.6666667 L53.3333333,46.6666667 L53.3333333,33.3333333 Z M30,53.3333333 L40,53.3333333 C41.8333333,53.3333333 43.3333333,51.8333333 43.3333333,50 L43.3333333,30 C43.3333333,28.1666667 41.8333333,26.6666667 40,26.6666667 L30,26.6666667 C28.1666667,26.6666667 26.6666667,28.1666667 26.6666667,30 L26.6666667,50 C26.6666667,51.8333333 28.1666667,53.3333333 30,53.3333333 Z M33.3333333,33.3333333 L36.6666667,33.3333333 L36.6666667,46.6666667 L33.3333333,46.6666667 L33.3333333,33.3333333 Z M20,26.6666667 C18.1666667,26.6666667 16.6666667,28.1666667 16.6666667,30 L16.6666667,50 C16.6666667,51.8333333 18.1666667,53.3333333 20,53.3333333 C21.8333333,53.3333333 23.3333333,51.8333333 23.3333333,50 L23.3333333,30 C23.3333333,28.1666667 21.8333333,26.6666667 20,26.6666667 Z M6.66666667,20 L6.66666667,60 C6.66666667,63.6666667 9.66666667,66.6666667 13.3333333,66.6666667 L66.6666667,66.6666667 C70.3333333,66.6666667 73.3333333,63.6666667 73.3333333,60 L73.3333333,20 C73.3333333,16.3333333 70.3333333,13.3333333 66.6666667,13.3333333 L13.3333333,13.3333333 C9.66666667,13.3333333 6.66666667,16.3333333 6.66666667,20 Z M63.3333333,60 L16.6666667,60 C14.8333333,60 13.3333333,58.5 13.3333333,56.6666667 L13.3333333,23.3333333 C13.3333333,21.5 14.8333333,20 16.6666667,20 L63.3333333,20 C65.1666667,20 66.6666667,21.5 66.6666667,23.3333333 L66.6666667,56.6666667 C66.6666667,58.5 65.1666667,60 63.3333333,60 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_LOCATION":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-chevron 2</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2147.000000)">
                                <g id="ic-logistics-chevron" transform="translate(809.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_ENTITIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-chevron 2</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2147.000000)">
                                <g id="ic-logistics-chevron" transform="translate(809.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_ENTITY_LIST":
            return (
                <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <title>ic-logistics-chevron 2</title>
                    <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Api-Catalog-icons" transform="translate(-809.000000, -2147.000000)">
                            <g id="ic-logistics-chevron" transform="translate(809.000000, 2147.000000)">
                                <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                    <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                    <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                </g>
                                <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                    <g>
                                        <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                        <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </svg>
            )
            case "PUT_ENTITIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-in-progress</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-1062.000000, -2147.000000)">
                                <g id="ic-logistics-in-progress" transform="translate(1062.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <g id="Group-2" transform="translate(5.000000, 7.000000)" fill="#F5F6F8">
                                                <path d="M22.5513573,4.88463648 C22.2413295,4.67315095 21.8187207,4.75312599 21.6074163,5.06306324 L20.2203973,7.09667551 C19.3437513,3.0462068 15.7302923,0 11.4237767,0 C9.0196345,0 6.75950176,0.936242371 5.05973775,2.63636867 C4.79445247,2.90165394 4.79454305,3.33178019 5.05982832,3.5969749 C5.3251136,3.86226017 5.75523985,3.86226017 6.02043455,3.59688432 C7.46351762,2.15343897 9.38246578,1.35857943 11.4236861,1.35857943 C15.1661196,1.35857943 18.2903994,4.0685831 18.9393475,7.62869521 L16.5295899,5.98508582 C16.219562,5.77350971 15.7969533,5.85357533 15.5856489,6.16351258 C15.3742539,6.47344984 15.4541384,6.89605861 15.7641662,7.10745357 L19.3611411,9.5607763 C19.3614128,9.56095745 19.361594,9.56104802 19.3617751,9.56122916 C19.4496299,9.62109723 19.5464513,9.65759773 19.6450842,9.67217982 C19.6778713,9.67698013 19.71093,9.67942557 19.7438982,9.67942557 C19.9609086,9.67942557 20.1742056,9.5756301 20.3057161,9.38289297 L22.7297841,5.82866804 C22.9412696,5.51864021 22.8612946,5.09603144 22.5513573,4.88463648 Z" id="Path"></path>
                                                <path d="M16.9547347,14.2731449 C15.4979752,15.8004599 13.5338317,16.6416017 11.4239578,16.6416017 C7.68179605,16.6415111 4.55742565,13.931598 3.90847754,10.3715765 L6.31832573,12.0151859 C6.43543528,12.0950704 6.56866664,12.1333823 6.70044884,12.1333823 C6.91745926,12.1333823 7.13075623,12.0296774 7.26226672,11.8368497 C7.47366168,11.5269125 7.39377721,11.1043037 7.08383996,10.8929087 L3.48686506,8.43958599 C3.48668392,8.43949541 3.48650277,8.43931427 3.48632163,8.43913313 C3.17620323,8.22764759 2.7537756,8.30762264 2.54238064,8.61755989 L0.118131507,12.1716942 C-0.0931728805,12.4816315 -0.013378982,12.9042403 0.296558272,13.1156352 C0.413667819,13.1955197 0.546899175,13.2337411 0.678681379,13.2337411 C0.895782372,13.2337411 1.10898877,13.1299456 1.24049926,12.9371179 L2.62742771,10.9035056 C3.5041643,14.9538838 7.11771387,18 11.4238672,18 C13.908709,18 16.2220981,17.0093239 17.9377122,15.2106553 C18.1966574,14.9392111 18.1865133,14.5091754 17.9150692,14.2502302 C17.6434439,13.9914661 17.2134988,14.0016102 16.9547347,14.2731449 Z" id="Path"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_PRODUCTS":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-chevron 2</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2147.000000)">
                                <g id="ic-logistics-chevron" transform="translate(809.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_PRODUCT_LIST":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-chevron 2</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2147.000000)">
                                <g id="ic-logistics-chevron" transform="translate(809.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_ENTITIES_BY_TYPE":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-chevron 2</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2147.000000)">
                                <g id="ic-logistics-chevron" transform="translate(809.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_ENTITIES":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-logistics-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -2147.000000)">
                                <g id="ic-logistics-chevron" transform="translate(559.000000, 2147.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="local_shipping_black_24dp" transform="translate(15.000000, 15.000000)">
                                        <polygon id="Path" points="0 0 70 0 70 70 0 70"></polygon>
                                        <path d="M58.8636364,24.3939394 L50.9090909,24.3939394 L50.9090909,18.030303 C50.9090909,14.530303 48.0454545,11.6666667 44.5454545,11.6666667 L6.36363636,11.6666667 C2.86363636,11.6666667 0,14.530303 0,18.030303 L0,46.6666667 C0,50.1666667 2.86363636,53.030303 6.36363636,53.030303 C6.36363636,58.3121212 10.6272727,62.5757576 15.9090909,62.5757576 C21.1909091,62.5757576 25.4545455,58.3121212 25.4545455,53.030303 L44.5454545,53.030303 C44.5454545,58.3121212 48.8090909,62.5757576 54.0909091,62.5757576 C59.3727273,62.5757576 63.6363636,58.3121212 63.6363636,53.030303 L66.8181818,53.030303 C68.5681818,53.030303 70,51.5984848 70,49.8484848 L70,39.2530303 C70,37.8848485 69.5545455,36.5484848 68.7272727,35.4348485 L61.4090909,25.6666667 C60.8045455,24.8712121 59.85,24.3939394 58.8636364,24.3939394 Z M15.9090909,56.2121212 C14.1590909,56.2121212 12.7272727,54.780303 12.7272727,53.030303 C12.7272727,51.280303 14.1590909,49.8484848 15.9090909,49.8484848 C17.6590909,49.8484848 19.0909091,51.280303 19.0909091,53.030303 C19.0909091,54.780303 17.6590909,56.2121212 15.9090909,56.2121212 Z M58.8636364,29.1666667 L65.1,37.1212121 L50.9090909,37.1212121 L50.9090909,29.1666667 L58.8636364,29.1666667 Z M54.0909091,56.2121212 C52.3409091,56.2121212 50.9090909,54.780303 50.9090909,53.030303 C50.9090909,51.280303 52.3409091,49.8484848 54.0909091,49.8484848 C55.8409091,49.8484848 57.2727273,51.280303 57.2727273,53.030303 C57.2727273,54.780303 55.8409091,56.2121212 54.0909091,56.2121212 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "SEND_OMNI_CHANNEL":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-notifications-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -2397.000000)">
                                <g id="ic-notifications-plus" transform="translate(559.000000, 2397.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <path d="M72.6008773,63.8461538 L72.6008773,45.3846154 C72.6008773,34.0492308 66.5454927,24.56 55.9854927,22.0492308 L55.9854927,19.5384615 C55.9854927,16.4738462 53.4747235,14 50.4101081,14 C47.3454927,14 44.9085696,16.4738462 44.9085696,19.5384615 L44.9085696,22.0492308 C34.3116465,24.56 28.293185,34.0123077 28.293185,45.3846154 L28.293185,63.8461538 L23.493185,68.6092308 C21.1670312,70.9353846 22.7916465,74.9230769 26.0778004,74.9230769 L74.7054927,74.9230769 C77.9916465,74.9230769 79.653185,70.9353846 77.3270312,68.6092308 L72.6008773,63.8461538 Z M50.4101081,86 C54.4716465,86 57.7947235,82.6769231 57.7947235,78.6153846 L43.0254927,78.6153846 C43.0254927,82.6769231 46.3116465,86 50.4101081,86 Z M31.1362619,22.2338462 C32.6870312,20.8307692 32.7239542,18.4307692 31.2470312,16.9538462 C29.8439542,15.5507692 27.5547235,15.5138462 26.1147235,16.88 C19.8008773,22.64 15.4439542,30.4676923 14.0408773,39.2553846 C13.7085696,41.5076923 15.4439542,43.5384615 17.733185,43.5384615 C19.5054927,43.5384615 21.0562619,42.2461538 21.3516465,40.4738462 C22.4593389,33.3107692 26.0039542,26.9230769 31.1362619,22.2338462 Z M74.8162619,16.88 C73.3393389,15.5138462 71.0501081,15.5507692 69.6470312,16.9538462 C68.1701081,18.4307692 68.2439542,20.7938462 69.7578004,22.1969231 C74.853185,26.8861538 78.4347235,33.2738462 79.5424158,40.4369231 C79.8008773,42.2092308 81.3516465,43.5015385 83.1608773,43.5015385 C85.413185,43.5015385 87.1854927,41.4707692 86.8162619,39.2184615 C85.413185,30.4676923 81.093185,22.6769231 74.8162619,16.88 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "SEND_EMAIL":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-notifications-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -2397.000000)">
                                <g id="ic-notifications-plus" transform="translate(559.000000, 2397.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <path d="M72.6008773,63.8461538 L72.6008773,45.3846154 C72.6008773,34.0492308 66.5454927,24.56 55.9854927,22.0492308 L55.9854927,19.5384615 C55.9854927,16.4738462 53.4747235,14 50.4101081,14 C47.3454927,14 44.9085696,16.4738462 44.9085696,19.5384615 L44.9085696,22.0492308 C34.3116465,24.56 28.293185,34.0123077 28.293185,45.3846154 L28.293185,63.8461538 L23.493185,68.6092308 C21.1670312,70.9353846 22.7916465,74.9230769 26.0778004,74.9230769 L74.7054927,74.9230769 C77.9916465,74.9230769 79.653185,70.9353846 77.3270312,68.6092308 L72.6008773,63.8461538 Z M50.4101081,86 C54.4716465,86 57.7947235,82.6769231 57.7947235,78.6153846 L43.0254927,78.6153846 C43.0254927,82.6769231 46.3116465,86 50.4101081,86 Z M31.1362619,22.2338462 C32.6870312,20.8307692 32.7239542,18.4307692 31.2470312,16.9538462 C29.8439542,15.5507692 27.5547235,15.5138462 26.1147235,16.88 C19.8008773,22.64 15.4439542,30.4676923 14.0408773,39.2553846 C13.7085696,41.5076923 15.4439542,43.5384615 17.733185,43.5384615 C19.5054927,43.5384615 21.0562619,42.2461538 21.3516465,40.4738462 C22.4593389,33.3107692 26.0039542,26.9230769 31.1362619,22.2338462 Z M74.8162619,16.88 C73.3393389,15.5138462 71.0501081,15.5507692 69.6470312,16.9538462 C68.1701081,18.4307692 68.2439542,20.7938462 69.7578004,22.1969231 C74.853185,26.8861538 78.4347235,33.2738462 79.5424158,40.4369231 C79.8008773,42.2092308 81.3516465,43.5015385 83.1608773,43.5015385 C85.413185,43.5015385 87.1854927,41.4707692 86.8162619,39.2184615 C85.413185,30.4676923 81.093185,22.6769231 74.8162619,16.88 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "SEND_SMS":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-notifications-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -2397.000000)">
                                <g id="ic-notifications-plus" transform="translate(559.000000, 2397.000000)">
                                    <polygon id="Path" points="0 0 100 0 100 100 0 100"></polygon>
                                    <path d="M72.6008773,63.8461538 L72.6008773,45.3846154 C72.6008773,34.0492308 66.5454927,24.56 55.9854927,22.0492308 L55.9854927,19.5384615 C55.9854927,16.4738462 53.4747235,14 50.4101081,14 C47.3454927,14 44.9085696,16.4738462 44.9085696,19.5384615 L44.9085696,22.0492308 C34.3116465,24.56 28.293185,34.0123077 28.293185,45.3846154 L28.293185,63.8461538 L23.493185,68.6092308 C21.1670312,70.9353846 22.7916465,74.9230769 26.0778004,74.9230769 L74.7054927,74.9230769 C77.9916465,74.9230769 79.653185,70.9353846 77.3270312,68.6092308 L72.6008773,63.8461538 Z M50.4101081,86 C54.4716465,86 57.7947235,82.6769231 57.7947235,78.6153846 L43.0254927,78.6153846 C43.0254927,82.6769231 46.3116465,86 50.4101081,86 Z M31.1362619,22.2338462 C32.6870312,20.8307692 32.7239542,18.4307692 31.2470312,16.9538462 C29.8439542,15.5507692 27.5547235,15.5138462 26.1147235,16.88 C19.8008773,22.64 15.4439542,30.4676923 14.0408773,39.2553846 C13.7085696,41.5076923 15.4439542,43.5384615 17.733185,43.5384615 C19.5054927,43.5384615 21.0562619,42.2461538 21.3516465,40.4738462 C22.4593389,33.3107692 26.0039542,26.9230769 31.1362619,22.2338462 Z M74.8162619,16.88 C73.3393389,15.5138462 71.0501081,15.5507692 69.6470312,16.9538462 C68.1701081,18.4307692 68.2439542,20.7938462 69.7578004,22.1969231 C74.853185,26.8861538 78.4347235,33.2738462 79.5424158,40.4369231 C79.8008773,42.2092308 81.3516465,43.5015385 83.1608773,43.5015385 C85.413185,43.5015385 87.1854927,41.4707692 86.8162619,39.2184615 C85.413185,30.4676923 81.093185,22.6769231 74.8162619,16.88 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_TRANSFER_FDX":
            case "POST_TRANSFER":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic -transfer-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -2647.000000)">
                                <g id="ic--transfer-plus" transform="translate(559.000000, 2647.000000)">
                                    <g id="sync-24px">
                                        <polygon id="Path" points="0 0 96 0 96 100 6.25194341e-15 100"></polygon>
                                        <path d="M48.6666667,17.8813934 L48.6666667,10.4230601 C48.6666667,8.54806005 46.5066667,7.63139339 45.2666667,8.96472672 L34.0666667,20.5897267 C33.2666667,21.4230601 33.2666667,22.7147267 34.0666667,23.5480601 L45.2266667,35.1730601 C46.5066667,36.4647267 48.6666667,35.5480601 48.6666667,33.6730601 L48.6666667,26.2147267 C61.9066667,26.2147267 72.6666667,37.4230601 72.6666667,51.2147267 C72.6666667,54.5063934 72.0666667,57.7147267 70.9066667,60.5897267 C70.3066667,62.0897267 70.7466667,63.7980601 71.8266667,64.9230601 C73.8666667,67.0480601 77.3066667,66.2980601 78.3866667,63.5063934 C79.8666667,59.7147267 80.6666667,55.5480601 80.6666667,51.2147267 C80.6666667,32.7980601 66.3466667,17.8813934 48.6666667,17.8813934 Z M48.6666667,76.2147267 C35.4266667,76.2147267 24.6666667,65.0063934 24.6666667,51.2147267 C24.6666667,47.9230601 25.2666667,44.7147267 26.4266667,41.8397267 C27.0266667,40.3397267 26.5866667,38.6313934 25.5066667,37.5063934 C23.4666667,35.3813934 20.0266667,36.1313934 18.9466667,38.9230601 C17.4666667,42.7147267 16.6666667,46.8813934 16.6666667,51.2147267 C16.6666667,69.6313934 30.9866667,84.5480601 48.6666667,84.5480601 L48.6666667,92.0063934 C48.6666667,93.8813934 50.8266667,94.7980601 52.0666667,93.4647267 L63.2266667,81.8397267 C64.0266667,81.0063934 64.0266667,79.7147267 63.2266667,78.8813934 L52.0666667,67.2563934 C50.8266667,65.9647267 48.6666667,66.8813934 48.6666667,68.7563934 L48.6666667,76.2147267 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_TRANSFER_FDX":
            case "DELETE_TRANSFER":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic -transfer-minus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-310.000000, -2647.000000)">
                                <g id="ic--transfer-minus" transform="translate(310.000000, 2647.000000)">
                                    <g id="sync-24px">
                                        <polygon id="Path" points="0 0 96 0 96 100 6.25194341e-15 100"></polygon>
                                        <path d="M48.6666667,17.8813934 L48.6666667,10.4230601 C48.6666667,8.54806005 46.5066667,7.63139339 45.2666667,8.96472672 L34.0666667,20.5897267 C33.2666667,21.4230601 33.2666667,22.7147267 34.0666667,23.5480601 L45.2266667,35.1730601 C46.5066667,36.4647267 48.6666667,35.5480601 48.6666667,33.6730601 L48.6666667,26.2147267 C61.9066667,26.2147267 72.6666667,37.4230601 72.6666667,51.2147267 C72.6666667,54.5063934 72.0666667,57.7147267 70.9066667,60.5897267 C70.3066667,62.0897267 70.7466667,63.7980601 71.8266667,64.9230601 C73.8666667,67.0480601 77.3066667,66.2980601 78.3866667,63.5063934 C79.8666667,59.7147267 80.6666667,55.5480601 80.6666667,51.2147267 C80.6666667,32.7980601 66.3466667,17.8813934 48.6666667,17.8813934 Z M48.6666667,76.2147267 C35.4266667,76.2147267 24.6666667,65.0063934 24.6666667,51.2147267 C24.6666667,47.9230601 25.2666667,44.7147267 26.4266667,41.8397267 C27.0266667,40.3397267 26.5866667,38.6313934 25.5066667,37.5063934 C23.4666667,35.3813934 20.0266667,36.1313934 18.9466667,38.9230601 C17.4666667,42.7147267 16.6666667,46.8813934 16.6666667,51.2147267 C16.6666667,69.6313934 30.9866667,84.5480601 48.6666667,84.5480601 L48.6666667,92.0063934 C48.6666667,93.8813934 50.8266667,94.7980601 52.0666667,93.4647267 L63.2266667,81.8397267 C64.0266667,81.0063934 64.0266667,79.7147267 63.2266667,78.8813934 L52.0666667,67.2563934 C50.8266667,65.9647267 48.6666667,66.8813934 48.6666667,68.7563934 L48.6666667,76.2147267 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_TRANSFER_FDX":
            case "GET_TRANSFERS_FDX":
            case "GET_TRANSFER":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic -transfer-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2647.000000)">
                                <g id="ic--transfer-chevron" transform="translate(809.000000, 2647.000000)">
                                    <g id="sync-24px">
                                        <polygon id="Path" points="0 0 96 0 96 100 6.25194341e-15 100"></polygon>
                                        <path d="M48.6666667,17.8813934 L48.6666667,10.4230601 C48.6666667,8.54806005 46.5066667,7.63139339 45.2666667,8.96472672 L34.0666667,20.5897267 C33.2666667,21.4230601 33.2666667,22.7147267 34.0666667,23.5480601 L45.2266667,35.1730601 C46.5066667,36.4647267 48.6666667,35.5480601 48.6666667,33.6730601 L48.6666667,26.2147267 C61.9066667,26.2147267 72.6666667,37.4230601 72.6666667,51.2147267 C72.6666667,54.5063934 72.0666667,57.7147267 70.9066667,60.5897267 C70.3066667,62.0897267 70.7466667,63.7980601 71.8266667,64.9230601 C73.8666667,67.0480601 77.3066667,66.2980601 78.3866667,63.5063934 C79.8666667,59.7147267 80.6666667,55.5480601 80.6666667,51.2147267 C80.6666667,32.7980601 66.3466667,17.8813934 48.6666667,17.8813934 Z M48.6666667,76.2147267 C35.4266667,76.2147267 24.6666667,65.0063934 24.6666667,51.2147267 C24.6666667,47.9230601 25.2666667,44.7147267 26.4266667,41.8397267 C27.0266667,40.3397267 26.5866667,38.6313934 25.5066667,37.5063934 C23.4666667,35.3813934 20.0266667,36.1313934 18.9466667,38.9230601 C17.4666667,42.7147267 16.6666667,46.8813934 16.6666667,51.2147267 C16.6666667,69.6313934 30.9866667,84.5480601 48.6666667,84.5480601 L48.6666667,92.0063934 C48.6666667,93.8813934 50.8266667,94.7980601 52.0666667,93.4647267 L63.2266667,81.8397267 C64.0266667,81.0063934 64.0266667,79.7147267 63.2266667,78.8813934 L52.0666667,67.2563934 C50.8266667,65.9647267 48.6666667,66.8813934 48.6666667,68.7563934 L48.6666667,76.2147267 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_RECURRING_PAYMENTS_FDX":
            case "GET_RECURRING_PAYMENT_FDX":
            case "GET_RECURRING_PAYMENTS_PAYMENTS_FDX":
            case "GET_PAYMENT_FDX":
            case "GET_PAYMENTS_FDX":
            case "GET_PAYMENT":
            case "GET_UTILITIES_LIST":
            case "GET_UTILITIES_BALANCE":
            case "POST_UTILITIES_PENDING_PAYMENTS":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-payments-chevron</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -2897.000000)">
                                <g id="ic-payments-chevron" transform="translate(809.000000, 2897.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="credit_card_black_24dp" transform="translate(6.000000, 6.000000)">
                                        <polygon id="Path" points="0 0 88 0 88 88 0 88"></polygon>
                                        <path d="M73.3333333,14.6666667 L14.6666667,14.6666667 C10.5966667,14.6666667 7.37,17.93 7.37,22 L7.33333333,66 C7.33333333,70.07 10.5966667,73.3333333 14.6666667,73.3333333 L73.3333333,73.3333333 C77.4033333,73.3333333 80.6666667,70.07 80.6666667,66 L80.6666667,22 C80.6666667,17.93 77.4033333,14.6666667 73.3333333,14.6666667 Z M69.6666667,66 L18.3333333,66 C16.3166667,66 14.6666667,64.35 14.6666667,62.3333333 L14.6666667,44 L73.3333333,44 L73.3333333,62.3333333 C73.3333333,64.35 71.6833333,66 69.6666667,66 Z M73.3333333,29.3333333 L14.6666667,29.3333333 L14.6666667,22 L73.3333333,22 L73.3333333,29.3333333 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "PATCH_PAYMENT_FDX":
            case "POST_PAYMENTS_FDX":
            case "PATCH_RECURRING_PAYMENTS_FDX":
            case "POST_RECURRING_PAYMENTS_FDX":
            case "POST_PAYMENT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-payments-plus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -2897.000000)">
                                <g id="ic-payments-plus" transform="translate(559.000000, 2897.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="credit_card_black_24dp" transform="translate(6.000000, 6.000000)">
                                        <polygon id="Path" points="0 0 88 0 88 88 0 88"></polygon>
                                        <path d="M73.3333333,14.6666667 L14.6666667,14.6666667 C10.5966667,14.6666667 7.37,17.93 7.37,22 L7.33333333,66 C7.33333333,70.07 10.5966667,73.3333333 14.6666667,73.3333333 L73.3333333,73.3333333 C77.4033333,73.3333333 80.6666667,70.07 80.6666667,66 L80.6666667,22 C80.6666667,17.93 77.4033333,14.6666667 73.3333333,14.6666667 Z M69.6666667,66 L18.3333333,66 C16.3166667,66 14.6666667,64.35 14.6666667,62.3333333 L14.6666667,44 L73.3333333,44 L73.3333333,62.3333333 C73.3333333,64.35 71.6833333,66 69.6666667,66 Z M73.3333333,29.3333333 L14.6666667,29.3333333 L14.6666667,22 L73.3333333,22 L73.3333333,29.3333333 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_PAYMENT_FDX":
            case "DELETE_RECURRING_PAYMENTS_FDX":
            case "DELETE_PAYMENT":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-payments-minus</title>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-310.000000, -2897.000000)">
                                <g id="ic-payments-minus" transform="translate(310.000000, 2897.000000)">
                                    <rect id="Rectangle" x="0" y="0" width="100" height="100"></rect>
                                    <g id="credit_card_black_24dp" transform="translate(6.000000, 6.000000)">
                                        <polygon id="Path" points="0 0 88 0 88 88 0 88"></polygon>
                                        <path d="M73.3333333,14.6666667 L14.6666667,14.6666667 C10.5966667,14.6666667 7.37,17.93 7.37,22 L7.33333333,66 C7.33333333,70.07 10.5966667,73.3333333 14.6666667,73.3333333 L73.3333333,73.3333333 C77.4033333,73.3333333 80.6666667,70.07 80.6666667,66 L80.6666667,22 C80.6666667,17.93 77.4033333,14.6666667 73.3333333,14.6666667 Z M69.6666667,66 L18.3333333,66 C16.3166667,66 14.6666667,64.35 14.6666667,62.3333333 L14.6666667,44 L73.3333333,44 L73.3333333,62.3333333 C73.3333333,64.35 71.6833333,66 69.6666667,66 Z M73.3333333,29.3333333 L14.6666667,29.3333333 L14.6666667,22 L73.3333333,22 L73.3333333,29.3333333 Z" id="Shape" fill="#7D8496" fill-rule="nonzero"></path>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "GET_WITHDRAWAL":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-withdrawals-chevron</title>
                        <defs>
                            <polygon id="path-1" points="0 0 100 0 100 100 0 100"></polygon>
                        </defs>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-809.000000, -3147.000000)">
                                <g id="ic-withdrawals-chevron" transform="translate(809.000000, 3147.000000)">
                                    <g id="Group-3">
                                        <mask id="mask-2" fill="white">
                                            <use href="#path-1"></use>
                                        </mask>
                                        <g id="Clip-2"></g>
                                        <g id="price_change_black_24dp" mask="url(#mask-2)" fill="#7D8496" fill-rule="nonzero">
                                            <g transform="translate(14.000000, 21.000000)" id="Shape">
                                                <path d="M64.8,0 L7.2,0 C3.204,0 0.036,3.204 0.036,7.2 L0,50.4 C0,54.396 3.204,57.6 7.2,57.6 L64.8,57.6 C68.796,57.6 72,54.396 72,50.4 L72,7.2 C72,3.204 68.796,0 64.8,0 Z M32.4,21.6 L21.6,21.6 L21.6,25.2 L32.4,25.2 C34.38,25.2 36,26.82 36,28.8 L36,39.6 C36,41.58 34.38,43.2 32.4,43.2 L28.8,43.2 L28.8,43.2 C28.8,45.18 27.18,46.8 25.2,46.8 L25.2,46.8 C23.22,46.8 21.6,45.18 21.6,43.2 L21.6,43.2 L18,43.2 C16.02,43.2 14.4,41.58 14.4,39.6 L14.4,39.6 C14.4,37.62 16.02,36 18,36 L28.8,36 L28.8,32.4 L18,32.4 C16.02,32.4 14.4,30.78 14.4,28.8 L14.4,18 C14.4,16.02 16.02,14.4 18,14.4 L21.6,14.4 L21.6,14.4 C21.6,12.42 23.22,10.8 25.2,10.8 L25.2,10.8 C27.18,10.8 28.8,12.42 28.8,14.4 L28.8,14.4 L32.4,14.4 C34.38,14.4 36,16.02 36,18 L36,18 C36,19.98 34.38,21.6 32.4,21.6 Z M49.14,42.84 L43.2,36.9 L57.6,36.9 L51.66,42.84 C50.976,43.524 49.824,43.524 49.14,42.84 Z M43.2,21.6 L49.14,15.66 C49.86,14.94 50.976,14.94 51.696,15.66 L57.6,21.6 L43.2,21.6 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400622,9.77668761 C29.5231248,6.90506266 27.4940623,4.4633752 24.9409373,2.73687524 C22.3885624,1.01037527 19.3058124,-0.000437216163 16.0000001,1.41868554e-07 C13.796625,1.41868554e-07 11.6908751,0.448750276 9.77681261,1.25987526 C6.90525016,2.47687524 4.4635002,4.5058127 2.73693774,7.05893766 C1.01043777,9.61118761 -0.000437216181,12.6940001 1.418598e-07,16.0000001 C1.418598e-07,18.2034375 0.448812776,20.3092499 1.25993776,22.2233124 C2.47687524,25.0949373 4.5060002,27.5366248 7.05906266,29.2631248 C9.61143761,30.9896247 12.6941876,32.0004372 16.0000001,32.0000001 C18.2033125,32.0000001 20.3091249,31.5512497 22.2231874,30.7401247 C25.0947498,29.5231873 27.5364998,27.4941873 29.2630623,24.9410623 C30.9896247,22.3887499 32.0004372,19.3058749 32.0000001,16.0000001 C32.0000001,13.7965 31.5511872,11.6907501 30.7400622,9.77668761 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M19.6588086,21.6439637 L12.1425146,15.660963 L19.6588086,9.67801715 C20.0098046,9.39860876 20.105672,8.83063914 19.872786,8.40944387 C19.6399457,7.98819379 19.1666377,7.87320776 18.8156416,8.15267096 L10.341085,14.8982625 C10.1286148,15.0673983 10,15.354973 10,15.660963 C10,15.966953 10.1285691,16.2544729 10.341085,16.4236635 L18.8155503,23.1693099 C19.166592,23.4487182 19.6399,23.3337322 19.8727403,22.9125369 C20.1055806,22.4913965 20.0098046,21.9233721 19.6588086,21.6439637 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "POST_WITHDRAWAL":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-withdrawals-plus</title>
                        <defs>
                            <polygon id="path-1" points="0 0 100 0 100 100 0 100"></polygon>
                        </defs>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-559.000000, -3147.000000)">
                                <g id="ic-withdrawals-plus" transform="translate(559.000000, 3147.000000)">
                                    <g id="Group-3">
                                        <mask id="mask-2" fill="white">
                                            <use href="#path-1"></use>
                                        </mask>
                                        <g id="Clip-2"></g>
                                        <g id="price_change_black_24dp" mask="url(#mask-2)" fill="#7D8496" fill-rule="nonzero">
                                            <g transform="translate(14.000000, 21.000000)" id="Shape">
                                                <path d="M64.8,0 L7.2,0 C3.204,0 0.036,3.204 0.036,7.2 L0,50.4 C0,54.396 3.204,57.6 7.2,57.6 L64.8,57.6 C68.796,57.6 72,54.396 72,50.4 L72,7.2 C72,3.204 68.796,0 64.8,0 Z M32.4,21.6 L21.6,21.6 L21.6,25.2 L32.4,25.2 C34.38,25.2 36,26.82 36,28.8 L36,39.6 C36,41.58 34.38,43.2 32.4,43.2 L28.8,43.2 L28.8,43.2 C28.8,45.18 27.18,46.8 25.2,46.8 L25.2,46.8 C23.22,46.8 21.6,45.18 21.6,43.2 L21.6,43.2 L18,43.2 C16.02,43.2 14.4,41.58 14.4,39.6 L14.4,39.6 C14.4,37.62 16.02,36 18,36 L28.8,36 L28.8,32.4 L18,32.4 C16.02,32.4 14.4,30.78 14.4,28.8 L14.4,18 C14.4,16.02 16.02,14.4 18,14.4 L21.6,14.4 L21.6,14.4 C21.6,12.42 23.22,10.8 25.2,10.8 L25.2,10.8 C27.18,10.8 28.8,12.42 28.8,14.4 L28.8,14.4 L32.4,14.4 C34.38,14.4 36,16.02 36,18 L36,18 C36,19.98 34.38,21.6 32.4,21.6 Z M49.14,42.84 L43.2,36.9 L57.6,36.9 L51.66,42.84 C50.976,43.524 49.824,43.524 49.14,42.84 Z M43.2,21.6 L49.14,15.66 C49.86,14.94 50.976,14.94 51.696,15.66 L57.6,21.6 L43.2,21.6 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g id="plus">
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.4186856e-07 C13.7966252,1.4186856e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.41859799e-07,16.0000001 C1.41859799e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.7429373,15.3496249 L17.4369999,15.3496249 L17.4369999,8.04368749 C17.4369999,7.4673125 16.9696874,7 16.3933124,7 C15.8169374,7 15.3496249,7.4673125 15.3496249,8.04368749 L15.3496249,15.3496249 L8.04368749,15.3496249 C7.4673125,15.3496249 7,15.8169374 7,16.3933124 C7,16.9696874 7.4673125,17.4369999 8.04368749,17.4369999 L15.3496249,17.4369999 L15.3496249,24.7429373 C15.3496249,25.3193123 15.8169374,25.7866248 16.3933124,25.7866248 C16.9696874,25.7866248 17.4369999,25.3193123 17.4369999,24.7429373 L17.4369999,17.4369999 L24.7429373,17.4369999 C25.3193123,17.4369999 25.7866248,16.9696874 25.7866248,16.3933124 C25.7866248,15.8169374 25.3193748,15.3496249 24.7429373,15.3496249 Z" id="Path" fill="#FFFFFF"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            case "DELETE_WITHDRAWAL":
                return (
                    <svg width={size} height={size} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <title>ic-withdrawals-minus</title>
                        <defs>
                            <polygon id="path-1" points="0 0 100 0 100 100 0 100"></polygon>
                        </defs>
                        <g id="⚙️-UI-System-(WIP)" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Api-Catalog-icons" transform="translate(-309.000000, -3147.000000)">
                                <g id="ic-withdrawals-minus" transform="translate(309.000000, 3147.000000)">
                                    <g id="Group-3">
                                        <mask id="mask-2" fill="white">
                                            <use href="#path-1"></use>
                                        </mask>
                                        <g id="Clip-2"></g>
                                        <g id="price_change_black_24dp" mask="url(#mask-2)" fill="#7D8496" fill-rule="nonzero">
                                            <g transform="translate(14.000000, 21.000000)" id="Shape">
                                                <path d="M64.8,0 L7.2,0 C3.204,0 0.036,3.204 0.036,7.2 L0,50.4 C0,54.396 3.204,57.6 7.2,57.6 L64.8,57.6 C68.796,57.6 72,54.396 72,50.4 L72,7.2 C72,3.204 68.796,0 64.8,0 Z M32.4,21.6 L21.6,21.6 L21.6,25.2 L32.4,25.2 C34.38,25.2 36,26.82 36,28.8 L36,39.6 C36,41.58 34.38,43.2 32.4,43.2 L28.8,43.2 L28.8,43.2 C28.8,45.18 27.18,46.8 25.2,46.8 L25.2,46.8 C23.22,46.8 21.6,45.18 21.6,43.2 L21.6,43.2 L18,43.2 C16.02,43.2 14.4,41.58 14.4,39.6 L14.4,39.6 C14.4,37.62 16.02,36 18,36 L28.8,36 L28.8,32.4 L18,32.4 C16.02,32.4 14.4,30.78 14.4,28.8 L14.4,18 C14.4,16.02 16.02,14.4 18,14.4 L21.6,14.4 L21.6,14.4 C21.6,12.42 23.22,10.8 25.2,10.8 L25.2,10.8 C27.18,10.8 28.8,12.42 28.8,14.4 L28.8,14.4 L32.4,14.4 C34.38,14.4 36,16.02 36,18 L36,18 C36,19.98 34.38,21.6 32.4,21.6 Z M49.14,42.84 L43.2,36.9 L57.6,36.9 L51.66,42.84 C50.976,43.524 49.824,43.524 49.14,42.84 Z M43.2,21.6 L49.14,15.66 C49.86,14.94 50.976,14.94 51.696,15.66 L57.6,21.6 L43.2,21.6 Z"></path>
                                            </g>
                                        </g>
                                    </g>
                                    <g id="Group" transform="translate(68.000000, 68.000000)" fill-rule="nonzero">
                                        <g>
                                            <path d="M30.7400625,9.7766877 C29.523125,6.90506272 27.4940625,4.46337524 24.9409376,2.73687526 C22.3885626,1.01037527 19.3058126,-0.000437216167 16.0000001,1.41868334e-07 C13.7966252,1.41868334e-07 11.6908752,0.44875028 9.7768127,1.25987527 C6.90525022,2.47687526 4.46350024,4.50581274 2.73693776,7.05893772 C1.01043777,9.6111877 -0.000437216184,12.6940002 1.418598e-07,16.0000001 C1.418598e-07,18.2034376 0.44881278,20.3092501 1.25993777,22.2233126 C2.47687526,25.0949376 4.50600024,27.536625 7.05906272,29.263125 C9.6114377,30.989625 12.6941877,32.0004375 16.0000001,32.0000001 C18.2033126,32.0000001 20.3091251,31.55125 22.2231876,30.740125 C25.0947501,29.5231875 27.5365,27.4941875 29.2630625,24.9410626 C30.989625,22.3887501 32.0004375,19.3058751 32.0000001,16.0000001 C32.0000001,13.7965002 31.5511875,11.6907502 30.7400625,9.7766877 Z" id="Shape" fill={portalColor}></path>
                                            <path d="M24.3562021,14.9416342 L7.64373533,14.9416342 C7.06690415,14.9416342 6.59922179,15.415519 6.59922179,16 C6.59922179,16.584481 7.06690415,17.0583658 7.64373533,17.0583658 L24.3562647,17.0583658 C24.9330959,17.0583658 25.4007782,16.584481 25.4007782,16 C25.4007782,15.415519 24.9330959,14.9416342 24.3562021,14.9416342 Z" id="Path" fill="#F5F6F8"></path>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                )
            default:
                return null;
        }
    };

    

    return (
        getIconByApiReference(props.iconReference, props.size)
    );    
}