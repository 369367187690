import React from 'react';
import Social from './components/Social.js'
import getUseCaseIcon from './Utils.js'
import { Link } from "react-router-dom";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import './styles/useCases.css';


class UseCases extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      selectedUseCase: '',
      useCasesArray: [],
      language: ''
    }
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    console.log('J', window.location.href.split('=').length);
    this.createUseCaseArray()
  }

  createUseCaseArray(){
    let uCArray = []
    for(let useCase in this.props.data.useCases){
      uCArray.push({
        icon: useCase,
        title: this.props.data.useCases[useCase].title
      })
    }
    this.setState({useCasesArray:uCArray, language: this.props.language})
  }


  render(){
    const { data, language } = this.props
    const { selectedUseCase, useCasesArray } = this.state
    let urlString = window.location.href.split('=')
    let selectedUC = ''
    if(urlString.length > 1){
      selectedUC = urlString[1]
    }

    if(selectedUC !== selectedUseCase){
      this.setState({selectedUseCase: selectedUC})
    }

    if(language !== this.state.language){
      this.createUseCaseArray()
    }

    return(
      <div style={{width:'100vw', height: '100vh'}}>
        <div className="banner">
          <img src="/banner.svg" alt="banner"/>
          {selectedUseCase?
            <div className="use-case-title">
              {getUseCaseIcon(selectedUseCase)}
              <p style={{whiteSpace: 'break-spaces'}}>{data.useCases[selectedUseCase].title}</p>
            </div>:
            <p>{data.mainTitle}</p>
          }
        </div>
        {selectedUseCase?
          <div className="use-case-container">
            <div className="use-case-card">
              <div className="use-case-top">
                <img
                  src={data.useCases[selectedUseCase].problem.image}
                  alt="problem"
                  className="use-case-image"
                  />
              </div>
              <div className="use-case-bottom">
                <h3>{language === 'en'? 'Problem' : 'Problema'}</h3>
                <p>{data.useCases[selectedUseCase].problem.text}</p>
              </div>
            </div>

            <div className="use-case-arrow">
              <ArrowRightAltIcon
                />
            </div>

            <div className="use-case-card">
              <div className="use-case-top">
                <img
                  src={data.useCases[selectedUseCase].solution.image}
                  alt="solution"
                  className="use-case-image"
                  />
              </div>
              <div className="use-case-bottom">
                <h3>{language === 'en'? 'Solution' : 'Solución'}</h3>
                <p>{data.useCases[selectedUseCase].solution.text}</p>
              </div>
            </div>
          </div>:
          <div className="use-cases-landing">
            <div className="use-cases-options">
              {useCasesArray.map((useCase, i) =>
                <Link
                  key={`key_${i}`}
                  to={`/use-cases/?use-case=${useCase.icon}`}
                  >
                  <div className="use-case-option" key={i}>
                    {getUseCaseIcon(useCase.icon)}
                    <p>{useCase.title}</p>
                  </div>
                </Link>
              )}
            </div>
          </div>
        }
        <Social/>
      </div>
    )
  }
}

export default UseCases;


// <img
//   className="use-case-image"
//   src={'image' in u && u.icon? `${u.icon}` : '/icons/icon-placeholder.png'}
//   alt={'icon' in u? u.icon : ''}/>
