import React from 'react';
import ImageBox from './components/ImageBox.js'
import Social from './components/Social.js'
import './styles/build.css';
import { Link } from "react-router-dom";


class Build extends React.Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }

  showContent(t, i){
    if(typeof t === 'object'){
      if('button' in t){
        if('external' in t){
          return(
            <a
              href={t.external}
              key={`button-key_${i}`}
              target="_blank"
              ><button className="step-button">{t.button}</button>
            </a>
          )
        }else if('internal' in t){
          return(
            <Link
              to={t.internal}
              key={`button-key_${i}`}
              ><button className="step-button">{t.button}</button>
          </Link>
          )
        }else{
          return ''
        }
      }else if('link' in t){
        if('external' in t){
          return(
            <a
              href={t.external}
              key={`link-key_${i}`}
              target="_blank"
              ><p className="step-link">{t.link}</p>
            </a>
          )
        }else if('internal' in t){
          return(
            <Link
              to={t.internal}
              key={`link-key_${i}`}
              ><p className="step-link">{t.link}</p>
          </Link>
          )
        }else{
          return ''
        }
      }else if('olist' in t){
        return(
          <ol key={`list-key_${i}`}>
            {t.olist.map((item, index) => <li>{item}</li>)}
          </ol>
        )
      }else if('ulist' in t){
        return(
          <ul key={`list-key_${i}`}>
            {t.ulist.map((item, index) => <li>{item}</li>)}
          </ul>
        )
      }else{
        return ''
      }
    }else{
      return <p className="step-text" key={`text-key_${i}`}>{t}</p>
    }

  }


  render(){
    const { data } = this.props

    return(
      <div>
        <div className="banner">
          <img src="/banner.svg" alt="banner"/>
          <p>{data.mainTitle}</p>
        </div>
        <div className="build-main">
          <div className="start-building">
            {'texts' in data && data.texts? data.texts.map((t,i) => <p className="step">{t}</p>) : ''}
            {data.steps.map((s,i) =>
              <div className="step" key={`key_${i}`}>
                <p className="step-title">{s.title}</p>
                {s.texts.map((t,index) =>
                  this.showContent(t, index)
                )}
              </div>
            )}
          </div>
        </div>
        <Social/>
      </div>
    )
  }
}

export default Build;
