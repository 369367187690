let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
let source  = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'DEVELOPER PORTAL',
    logo: '/logo-fihogar.png',
    links: [
      {
        path: '/catalog',
        label: 'API Catalog',
        separator: false,
        megaMenu: ''
      },
      {
        path: '/use-cases',
        label: 'Use Cases',
        separator: false,
        megaMenu: 'useCases'
      },
      {
        path: '/build',
        label: 'Start Building',
        separator: false,
        megaMenu: ''
      },
      {
        path: '/about',
        label: 'About Us',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: false,
        megaMenu: ''
      },
      {
        path: `${marketplace}/fihogar/login?source=${source}`,
        label: 'Login',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: true,
        megaMenu: ''
      },
      {
        path: `${marketplace}/fihogar/register?source=${source}`,
        label: 'Sign Up',
        separator: false,
        megaMenu: ''
      },
    ],
    megaMenus: {
      useCases: {
        messageTitle: 'Use Cases',
        message: "Got a Painpoint? Let's build the best solutions",
        useCaseList: {
          columnOne: [
            {
              icon: '/icons/ico-idea.svg',
              text: 'Mobile App Transfers to Bank Accounts',
              path: '/use-cases/?use-case=transfer'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Access to ATM Network',
              path: '/use-cases/?use-case=atm'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Utilities Payments',
              path: '/use-cases/?use-case=services'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'QR Code Payments',
              path: '/use-cases/?use-case=qr'
            }
          ],
          columnTwo: [
            {
              icon: '/icons/ico-idea.svg',
              text: 'Cash in-Cash out',
              path: '/use-cases/?use-case=cash'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Messaging',
              path: '/use-cases/?use-case=message'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Chatbot',
              path: '/use-cases/?use-case=chatbot'
            }
          ]
        }
      }
    }
  },
  es: {
    mainTitle: 'DEVELOPER PORTAL',
    logo: '/logo-fihogar.png',
    links: [
      {
        path: '/catalog',
        label: 'Catálogo de APIs',
        separator: false,
        megaMenu: ''
      },
      {
        path: '/use-cases',
        label: 'Casos de Uso',
        separator: false,
        megaMenu: 'useCases'
      },
      {
        path: '/build',
        label: 'Empezar',
        separator: false,
        megaMenu: ''
      },
      {
        path: '/about',
        label: 'Conócenos',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: false,
        megaMenu: ''
      },
      {
        path: `${marketplace}/fihogar/login?source=${source}`,
        label: 'Inicia Sesión',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: true,
        megaMenu: ''
      },
      {
        path: `${marketplace}/fihogar/register?source=${source}`,
        label: 'Registro',
        separator: false,
        megaMenu: ''
      },
    ],
    megaMenus: {
      useCases: {
        messageTitle: 'Use Cases',
        message: "Got a Painpoint? Let's build the best solutions",
        useCaseList: {
          columnOne: [
            {
              icon: '/icons/ico-idea.svg',
              text: 'Transferencias de Aplicación Móvil a Cuenta Bancaria',
              path: '/use-cases/?use-case=transfer'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Acceso a red de cajeros ATM',
              path: '/use-cases/?use-case=atm'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Pagos de Servicios',
              path: '/use-cases/?use-case=services'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Pagos en Comercios con Código QR',
              path: '/use-cases/?use-case=qr'
            }
          ],
          columnTwo: [
            {
              icon: '/icons/ico-idea.svg',
              text: 'Cash in-Cash out',
              path: '/use-cases/?use-case=cash'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Mensajería',
              path: '/use-cases/?use-case=message'
            },
            {
              icon: '/icons/ico-idea.svg',
              text: 'Chatbot',
              path: '/use-cases/?use-case=chatbot'
            }
          ]
        }
      }
    }
  }
}
