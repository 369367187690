let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"

export default {
  en: {
    mainTitle: 'USE CASES',
    useCases: {
      transfer: {
        title: 'Mobile App Transfers to Bank Accounts',
        problem: {
          image: '/Transferencias.png',
          text: '3rd parties are not able to connect to the Securities Payment and Settlement System in the Dominican Republic (SIPARD), specifically to the system of  Liquidación Bruta en Tiempo Real (LBTR) of the Central Bank of the Dominican Republic. This does not allow them to be able to make money transfers from mobile payment applications to bank accounts.'
        },
        solution: {
          image: '/Solucion.png',
          text: "Fihogar's developer portal simplifies transfers of mobile applications to bank accounts. By registering on the portal, providers can connect to the LBTR through the payment APIs and make transfers via SWIFT using the Banco Fihogar reconciliation account. The portal allows developers to quickly include the new payment functionality in their mobile applications and improve user experience."
        }
      },
      atm: {
        title: 'Access to ATM Network',
        problem: {
          image: '/Atm.png',
          text: 'Mobile applications that want to offer cardless withdrawals at ATMs, require connecting to a network of ATMs and have authorization of a payment processor or a local bank, and this implies the allocation of resources.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'The Fihogar developer portal offers registered developers the possibility to integrate with Finconecta’s withdrawal API and thus become part of a network of financial institutions that allows them to offer cardless cash withdrawals.'
        }
      },
      services: {
        title: 'Utilities Payments',
        problem: {
          image: '/Servicios.png',
          text: 'Offering utilities payments requires mobile payment applications to establish alliances with aggregators and integrate modules for authentication, transaction, and reconciliation into their systems.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'Fihogar, through its developer portal, offers the necessary modules to quickly add the utilities payment functionality to mobile applications. By connecting to the authentication and payment APIs, developers get the pieces they need to launch this new product.'
        }
      },
      qr: {
        title: 'QR Code Payments',
        problem: {
          image: '/QR.png',
          text: 'In order to generate traction with QR payments, an effort must be made to set up a network of businesses enabled to set up a payment and design an affiliation process, which is expensive and takes time.'
        },
        solution: {
          image: '/Solucion.png',
          text: "Fihogar's developer portal allows mobile applications to swiftly join a network of already affiliated businesses and thus rapidly have QR payment points."
        }
      },
      cash: {
        title: 'Cash In-Cash Out',
        problem: {
          image: '/Cash.png',
          text: 'Applications interested in adding cash in-cash out functionalities require setting up a network of stores enabled as points of sale to recharge balances and withdraw cash, which is expensive and takes time.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'The Fihogar developer portal allows mobile applications to be quickly incorporated , into a network of already affiliated businesses and in turn be able to rapidly incorporate cash in - cash out functionalities.'
        }
      },
      message: {
        title: 'Messaging',
        problem: {
          image: '/Mensajeria.png',
          text: 'Notifications via SMS or email, whether for validations, transaction details, marketing campaigns, personalized messaging, intervene throughout the customer journey. Developers who want to improve the user experience through messaging should seek alliances with third parties and invest in implementations that suit their needs.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'To integrate SMS or email messaging into their applications, developers will find the key pieces they need within Fihogar’s developer portal and will be able to test their implementation in the sandbox before taking them into production.'
        }
      },
      chatbot: {
        title: 'Chatbot',
        problem: {
          image: '/Chatbot.png',
          text: 'Today more than ever it is imperative to stay close to the customer. To improve the user experience, applications must be able to support their customers 24/7 through chatbots using artificial intelligence. Including this functionality within your solution requires seeking partnerships with third parties, allocating resources, and investing in implementations.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'To integrate an artificial intelligence chatbot into their applications,  developers only need to register in Fihogar’s developer portal. There they will find the testing and production functionalities.'
        }
      }
    }
  },
  es: {
    mainTitle: 'CASOS DE USO',
    useCases: {
      transfer: {
        title: 'Transferencias de Aplicación Móvil a Cuenta Bancaria',
        problem: {
          image: '/Transferencias.png',
          text: 'Para poder realizar transferencias de dinero desde  las aplicaciones móviles de pagos a cuentas bancarias, terceros no están habilitados para conectarse al Sistema de Pagos y Liquidación de Valores  en República Dominicana (SIPARD) en específico al Sistema de Liquidación Bruta en Tiempo Real (LBTR) del Banco Central de la República Dominicana.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'El portal para desarrolladores de Fihogar simplifica las transferencias de aplicaciones móviles a cuentas bancarias. Al registrarse en el portal, los proveedores pueden conectarse al LBTR a través de las APIs de pagos, realizar las transferencias vía SWIFT utilizando la cuenta de conciliación de Banco Fihogar. El portal permite que los desarrolladores puedan rápidamente incluir en su aplicaciones móviles nuevas funcionalidades de pago para mejorar la experiencia del usuario.'
        }
      },
      atm: {
        title: 'Acceso a red de cajeros ATM',
        problem: {
          image: '/Atm.png',
          text: 'Las aplicaciones móviles que quieren ofrecer a sus usuarios retiro sin tarjeta en cajeros ATM, requieren conectarse a una red de cajeros y ser autorizados por un procesador de pagos o un banco local,  lo cual implica la asignación de recursos.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'El portal para desarrolladores de Fihogar ofrece a los desarrolladores registrados, la posibilidad de integrarse a la API de retiros y así formar parte de una red de instituciones financieras que les permita ofrecer el retiro de efectivo sin tarjeta.'
        }
      },
      services: {
        title: 'Pagos de Servicios',
        problem: {
          image: '/Servicios.png',
          text: 'Ofrecer a los usuarios pago de servicios requiere que las aplicaciones móviles de pagos establezcan alianzas con agregadores e integren a sus sistemas módulos para la autenticación, transacción y conciliación.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'Fihogar a través de su portal para desarrolladores, ofrece los módulos necesarios para agregar rápidamente la funcionalidad de pagos de servicios a las aplicaciones móviles. Conectándose a las APIs de autenticación y pagos, los desarrolladores obtienen las piezas que necesitan para lanzar el nuevo producto.'
        }
      },
      qr: {
        title: 'Pagos en Comercios con Código QR',
        problem: {
          image: '/QR.png',
          text: 'Para generar tracción con los pagos QR  se debe realizar el esfuerzo de montar una red de comercios habilitados para  aceptación del pago  y diseñar un proceso de afiliación, lo cual es costoso y toma tiempo.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'El portal para desarrolladores de Fihogar permite que las aplicaciones móviles puedan incorporarse, rápidamente, a una red de comercios ya afiliados y así rápidamente contar con puntos de pago con QR.  '
        }
      },
      cash: {
        title: 'Cash in-Cash out',
        problem: {
          image: '/Cash.png',
          text: 'Las aplicaciones interesadas en añadir funcionalidades de cash in-cash out requieren montar una red de comercios habilitados como puntos de venta para recargar balances y retirar efectivo, lo cual es costoso y toma tiempo.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'El portal para desarrolladores de Fihogar permite que las aplicaciones móviles puedan incorporarse, rápidamente, a una red de comercios ya afiliados y así rápidamente incorporar las funcionalidades de cash in- cash out'
        }
      },
      message: {
        title: 'Mensajería',
        problem: {
          image: '/Mensajeria.png',
          text: 'Las notificaciones via SMS o correo electrónico ya sea para validaciones, detalle de transacciones, campañas de marketing, mensajeria personalizada intervienen a lo largo del customer journey.  Los desarrolladores que quieren mejorar la experiencia del usuario mediante mensajería deben buscar alianzas con terceros e invertir en implementaciones que se ajusten a sus necesidades'
        },
        solution: {
          image: '/Solucion.png',
          text: 'Para integrar mensajería vía SMS o correo electrónico a sus aplicaciones, los desarrolladores encontrarán las piezas que necesitan dentro del portal para desarrolladores de Fihogar y podrán testear la implementación en el sandbox antes de llevarla a producción.'
        }
      },
      chatbot: {
        title: 'Chatbot',
        problem: {
          image: '/Chatbot.png',
          text: 'Hoy más que nunca es imperativo mantenerse cerca del cliente. Para mejorar la experiencia del usuario, las  aplicaciones deben ser capaces de dar soporte 24/7 a sus clientes mediante chatbots con inteligencia artificial. Incluir esta funcionalidad dentro de sus solución requiere la búsqueda de alianzas con terceros, asignar recursos e invertir en implementaciones.'
        },
        solution: {
          image: '/Solucion.png',
          text: 'Para integrar un  chatbot de inteligencia artificial sus aplicaciones, basta con que los  desarrolladores se registren al portal para desarrolladores de Fihogar. Ahí encontrarán la funcionalidad disponible para testeo y producción.'
        }
      }
    }
  },
}
